const BankDetailsIcon = ({ val }) => {
    return (
        <>
            {
         !val ?
                    <svg width="18" height="21" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.5588 21.9028H22.4388M17.2779 10.4442H20.7971V21.9028H17.2779V10.4442ZM10.239 10.4442H13.7586V21.9028H10.239V10.4442ZM3.20049 10.4442H6.71972V21.9028H3.20049V10.4442ZM23.0757 10.4442H0.921875L11.9988 1.09668L23.0757 10.4442Z" stroke="#111111" strokeMiterlimit="10" stroke-linecap="round" stroke-linejoin="round" fill={val ? "#fff" : ""} />
                    </svg> :
                    <svg width="18" height="21" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 1.55176L24 6.35176V7.95175H22.4C22.4 8.16842 22.3146 8.35592 22.1438 8.51425C21.9729 8.67259 21.7708 8.75176 21.5375 8.75176H2.4625C2.22917 8.75176 2.02708 8.67259 1.85625 8.51425C1.68542 8.35592 1.6 8.16842 1.6 7.95175H0V6.35176L12 1.55176ZM3.2 9.55175H6.4V19.1518H8V9.55175H11.2V19.1518H12.8V9.55175H16V19.1518H17.6V9.55175H20.8V19.1518H21.5375C21.7708 19.1518 21.9729 19.2309 22.1438 19.3892C22.3146 19.5476 22.4 19.7351 22.4 19.9517V20.7518H1.6V19.9517C1.6 19.7351 1.68542 19.5476 1.85625 19.3892C2.02708 19.2309 2.22917 19.1518 2.4625 19.1518H3.2V9.55175ZM23.1375 21.5518C23.3708 21.5518 23.5729 21.6309 23.7438 21.7892C23.9146 21.9476 24 22.1351 24 22.3517V23.9518H0V22.3517C0 22.1351 0.0854152 21.9476 0.256246 21.7892C0.427085 21.6309 0.62917 21.5518 0.8625 21.5518H23.1375Z" fill="white" />
                        <circle cx="12" cy="6.5" r="1" fill="white" />
                    </svg>
            }
        </>
    )
}
export default BankDetailsIcon;