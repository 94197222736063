import React, { useEffect, useState } from "react";
import {Form, Row, Col, Input, Button} from 'antd';
import PropTypes from 'prop-types'
import { useStripe } from "@stripe/react-stripe-js";
import { addbankdetail, getBankDetail } from "@/api/subscribe";
import { message, Tooltip } from "antd";
import infocircle from '@/assets/images/Info-Circle.svg'
import Spinner from "@/common/component/Spinner";
import { setScreen } from "@/store/loginslice/LoginSlice";
import { useDispatch } from "react-redux";
import SpinWrapper from '@/components/wrapper/SpinWrapper';
import { setLoader } from '@/store/loginslice/LoaderSlice'
import MembershipCard from "@/pages/membershippackage/MembershipCard";
import { listMembership } from "@/api/membership";
import '../style.less'

const SelectMembership = ({data, helpers: {setCurrentStep, setMembership}}) => {
  const [membershipList, setMembershipList] = useState([])

  useEffect(() => {
    getMembershipList()
  }, [])

  const getMembershipList = async () => {
    const res = await listMembership({ page: 1, pageLimit: 20, isActive: true });
    if (res.status === 200) {
      setMembershipList(res.data?.output?.list || []);
    }
  }

  const setSelectedMembership = (value) => {
    setMembership(value)
    setCurrentStep(3)
  }

  return (
    <div className={"center-container"}>
      <div className="welcome-container mt-4">
        <div className="membershipPackages">
          {membershipList?.map(item => (
            <MembershipCard item={item} setDetail={setSelectedMembership} button="Select" hideReadMore />
          ))}
        </div>
      </div>
    </div>
  )
}

export default SelectMembership
