import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import './dashboardwrapper.less';
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const DashboardWrapper = ({ children }) => {
  const noNavbar = useSelector(state => state.subscribeCustomer?.noNavbar)

  return (
    <>
      {!noNavbar ?
        <div className="dashboard-right">
          <Navbar />
          <div className="dashboard-content">
            <div className="component-wrapper">
              {children}
            </div>
          </div>
        </div> :
        <div className="dashboard-right">
          <div className="dashboard-content">
            <div className="component-wrapper">
              {children}
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default DashboardWrapper;