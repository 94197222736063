import instance from "../config/axios";

export async function addMembership(data) {
  return instance.post(`${process.env.REACT_APP_BASE_URL}membership/create`, data)
}

export async function editMembership(data) {
  return instance.post(`${process.env.REACT_APP_BASE_URL}membership/edit`, data)
}

export async function listMembership(page) {
  return instance.post(`${process.env.REACT_APP_BASE_URL}membership/list`,  page)
}
export async function getMembershipById(id) {
  return instance.get(`${process.env.REACT_APP_BASE_URL}membership/view/${id}`)
}