import { useSelector } from "react-redux";
import Spin from 'antd/es/spin'

const SpinWrapper = ({ children }) => {
  const loader = useSelector(state => state.loader.loader);

  return (
    <Spin spinning={loader} >
      {children}
    </Spin>
  )
}

export default SpinWrapper;