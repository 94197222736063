const SubscriptionIcon = ({ val }) => {
  return (
    <>
      <svg width="18" height="21" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" fill={val ? "#fff" : ""} clip-rule="evenodd" d="M15.857 17.917C17.732 17.917 19.25 16.398 19.25 14.524V11.824C18.01 11.824 17.01 10.824 17.01 9.585C17.01 8.345 18.01 7.346 19.25 7.346L19.248 4.643C19.248 2.769 17.73 1.25 15.856 1.25H4.144C2.27 1.25 0.751 2.769 0.751 4.643L0.75 7.433C1.989 7.433 2.989 8.345 2.989 9.585C2.989 10.824 1.989 11.824 0.75 11.824V14.524C0.75 16.398 2.268 17.917 4.142 17.917H15.857Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M9.99835 11.3545L11.7383 12.2695C11.9013 12.3545 12.0923 12.2165 12.0613 12.0355L11.7283 10.0965L13.1373 8.72553C13.2693 8.59653 13.1963 8.37353 13.0143 8.34653L11.0683 8.06353L10.1973 6.29953C10.1163 6.13453 9.88135 6.13453 9.79935 6.29953L8.92835 8.06353L6.98335 8.34653C6.80135 8.37353 6.72835 8.59653 6.86035 8.72553L8.26835 10.0965L7.93535 12.0355C7.90435 12.2165 8.09535 12.3545 8.25835 12.2695L9.99835 11.3545Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </>
  )
}
export default SubscriptionIcon;