import { createSlice } from "@reduxjs/toolkit";
export const customerSlice = createSlice({
  name: 'customer',
  initialState: {
   customerId:''
  },
  reducers: {
    setCustomerId: (state, action) => {
      state.customerId = action.payload;
    }
  }
})
export const { setCustomerId} = customerSlice.actions;
export default customerSlice.reducer;