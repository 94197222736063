import React, { useEffect, useState } from "react";
import {Form, Row, Col, Input, Button, message, Tooltip} from 'antd';
import PropTypes from 'prop-types'
import { useStripe } from "@stripe/react-stripe-js";
import { addbankdetail, getBankDetail } from "@/api/subscribe";
import infocircle from '@/assets/images/Info-Circle.svg'
import Spinner from "@/common/component/Spinner";
import { setScreen } from "@/store/loginslice/LoginSlice";
import { useDispatch } from "react-redux";
import SpinWrapper from '@/components/wrapper/SpinWrapper';
import { setLoader } from '@/store/loginslice/LoaderSlice'
import { generate } from "generate-password-browser";
import { buyCustomerMembership, createCustomerProfile, customerSignup, sendWelcomeEmail } from "@/api/customerapi";
import { useForm } from "antd/es/form/Form";
import dummyprofile from '@/assets/images/dummyprofile.png'
import CreditCard from "../components/CreditCard";
import MembershipCard from "@/pages/membershippackage/MembershipCard";
import { useNavigate } from "react-router-dom";

const Summary = ({data: {user, paymentMethod, membership, existingCustomer}, helpers: {setCurrentStep, setUser}}) => {
  const navigate = useNavigate()

  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();

  const handleConfirmation = async () => {
    dispatch(setLoader(true))

    try {
      const membershipData = {
        userId: user._id,
        membershipId: membership._id
      }

      const res = await buyCustomerMembership(membershipData)

      if (res?.data.status === 200) {
        // Show Success and navigate to subscribed customer
        if (!existingCustomer) {
          const welcomeEmail = await sendWelcomeEmail({email: user.email})

          if (welcomeEmail?.data.status === 200) {
            navigateToCustomer(user._id)
          }
        } else {
          navigateToCustomer(user._id)
        }

      }
    } catch (error) {
      dispatch(setLoader(false))
      message.error(error?.response?.data?.error?.message);
    }
  }

  const navigateToCustomer = (id) => {
    message.success('Customer sucessfully subscribed')
    navigate(`/subscribed-customers?id=${id}`)
  }

  return (
    <div className={"center-container"}>
      <div className="welcome-container mt-4">
        <h1 className="heading">
          Summary
        </h1>
        <hr />
        <Row>
          <Col xs={24} xxl={16}>
            <div className="customerdetails d-flex mt-3">
              <div className="profileinfo ">
                <div className="profileinfoone d-flex">
                  <div className="profilenameemailimg d-flex" >
                    <div className="profileimg">
                      <img src={user.profilePicture ? user.profilePicture : dummyprofile} style={{ width: "100%", height: '100%', borderRadius: "50%" }} />
                    </div>
                    <div className="profilenameandemail">
                      <div className="profilename" style={{ color: "#003B5B", fontWeight: "600" }}>{user?.firstName} {user?.lastName}</div>
                      <div className="profilemail" style={{ color: "#111111", fontSize: "19px", fontWeight: "400" }}>{user?.email}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <Row>
              <Col xs={24} xl={12}>
                <CreditCard card={paymentMethod} />
              </Col>
              <Col xs={24} lg={12}>
                <MembershipCard item={membership} button="Change Membership" setDetail={() => setCurrentStep(2)} hideReadMore />
              </Col>
            </Row>

            <hr />
            <Button
              htmlType="submit"
              className="mt-3"
              type="primary"
              size="large"
              block
              disabled={disabled}
              onClick={handleConfirmation}
            >
                Confirm Subscription
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Summary
