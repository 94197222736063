import './settings.less'
const ContactUs = () => {
  return (
    <div className="settingcard">
      <div className="contactuscard">
        {/* <div className="contactheading">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.
        </div> */}
        <div className="emailbtn">
          <div className="emailinfo">support@bnkd.com</div>
          < a className="emailref" href="mailto:support@bnkd.com" style={{ color: "white", fontWeight: "normal !important", textAlign: "center", fontSize: "14px", textDecoration: "none" }}>  <div className="emailbtn">Email</div></a>
        </div>
        <div className="slaheading">SLA & Process</div>
        <div className="slacontent"><span className="slaheading">1. Availability and Uptime: </span>We are committed to providing you with uninterrupted access to the BNKD business web portal. Our goal is to maintain a minimum uptime of 99.9%. In case of scheduled maintenance or unexpected downtime, we will notify you in advance. </div>
        <div className="slacontent"><span className="slaheading">2. Support Response Time:</span>Our support team is dedicated to assisting you promptly. We aim to respond to your inquiries within 24 hours during business days (Monday to Friday). </div>
        <div className="slacontent"><span className="slaheading">3. Issue Resolution: </span>We prioritize resolving any technical issues you encounter. Our team will work diligently to address and resolve reported issues in a timely manner. The timeframe for resolution may vary based on the complexity of the issue. </div>
        <div className="slacontent"><span className="slaheading">4. Data Security: </span>Your data security is our priority. We implement industry-standard security measures to protect your business and customer data from unauthorized access, breaches, and other threats. </div>
        <div className="slacontent"><span className="slaheading">5. Updates and Enhancements: </span>We continuously strive to improve our platform. Updates and enhancements to the BNKD business web portal will be communicated in advance, along with relevant instructions on how to make the most of new features. </div>
        <div className="contactus">Contact Us </div>
        <div className="slacontent">

          For assistance, inquiries, or support, please reach out to our dedicated support team: <br />
          <span className="contactinfo">Email: support@bnkd.ca </span><br />

          <span className="contactinfo">Business Hours: Monday to Friday, 9:00 AM - 5:00 PM [MST] </span><br />

          We appreciate your business and look forward to providing you with exceptional service. Your success is our priority.
        </div>
      </div>

    </div>
  )
}
export default ContactUs;