import instance from "../config/axios";

export async function getBusinessReport() {
  try {
    const val = await instance.post(`${process.env.REACT_APP_BASE_URL}user/business-analytic-report`, null)
    return val;
  }
  catch (err) {
    //console.log(err,"....err")
  }
}

export async function getBusinessGraphData(data) {
  try {
    const val = await instance.post(`${process.env.REACT_APP_BASE_URL}user/business-graph-data`, data)
    return val;
  }
  catch (err) {
    return err;
    //console.log(err,"....err")
  }
}