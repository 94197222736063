import React, { useEffect, useState } from 'react';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import PropTypes from 'prop-types';
import { useStripe } from '@stripe/react-stripe-js';
import { addbankdetail, getBankDetail } from '../../api/subscribe';
import { message, Tooltip } from 'antd';
import infocircle from '../../assets/images/Info-Circle.svg';
import Spinner from '../../common/component/Spinner';
import { setScreen } from '../../store/loginslice/LoginSlice';
import { useDispatch } from 'react-redux';
import SpinWrapper from '../../components/wrapper/SpinWrapper';
import { setLoader } from '../../store/loginslice/LoaderSlice';

const BankDetail = ({ heading, setShowModal, isDisabled }) => {
  const stripe = useStripe();
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname === '/settings') {
      getBankDetail().then((res) => {
        const details = res.data?.output?.bankDetails;
        if (details) {
          form.setFieldsValue({
            institutionNumber: details.institutionNumber,
            transitNumber: details.transitNumber,
            accountNumber: details.accountNumber,
          });
          setDisabled(false);
        }
      });
    }
  }, []);

  const onFinish = async (values) => {
    const bank_account = {
      country: 'CA',
      currency: 'cad',
      account_holder_name: 'Zach',
      account_holder_type: 'individual',
      routing_number: `0${values.institutionNumber}${values.transitNumber}`,
      account_number: values.accountNumber,
    };

    if (values.institutionNumber?.length !== 3) {
      message.error('Invalid institution number');
      return;
    } else if (values.transitNumber?.length !== 5) {
      message.error('Invalid transit number');
      return;
    }

    try {
      // setLoading(true);
      dispatch(setLoader(true));
      const token = await stripe.createToken('bank_account', {
        bank_account,
      });

      if (token.token) {
        const res = await addbankdetail({
          bankName: token.token?.bank_account?.bank_name,
          institutionNumber: values.institutionNumber,
          transitNumber: values.transitNumber,
          accountNumber: values.accountNumber,
          // accountNumber: "000123456789",
          token: token.token?.id,
        });

        if (res.status === 200) {
          dispatch(setScreen(3));
          // setLoading(false);
          dispatch(setLoader(false));
          setShowModal(true);
        }
      } else {
        // setLoading(false);
        dispatch(setLoader(false));
        const msg = token.error.message?.split('.');
        message.error(msg ? msg[0] : 'Invalid institution or transit number');
      }
    } catch (error) {
      // setLoading(false);
      dispatch(setLoader(false));
      message.error(error?.response?.data?.error?.message);
    }
  };

  const handleFormChange = () => {
    const hasErrors =
      !form.isFieldsTouched(true) || form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    setDisabled(hasErrors);
  };

  return (
    <div className={heading ? 'center-container' : 'center-container mx-auto'}>
      <div className="welcome-container mt-4">
        <h1 className="heading">{heading || 'Enter Your Bank Information'}</h1>

        {/* <Spinner loading={loading} /> */}
        <SpinWrapper>
          <Form
            name="name-form"
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            onFieldsChange={handleFormChange}
            form={form}
          >
            <div className="label">
              Institution Number
              <div>
                <Tooltip
                  placement="bottomLeft"
                  title="(3) numbers indicating banking institution"
                  arrow={false}
                  overlayInnerStyle={{
                    color: '#6B6B6B',
                    backgroundColor: '#fff',
                    width: '300px',
                    fontSize: '12px',
                    fontWeight: '400',
                  }}
                  overlayStyle={{
                    color: '#fff',
                  }}
                >
                  <span className="infocircle">
                    <img
                      style={{ width: '30px', height: '18px', marginLeft: '5px', cursor: 'pointer' }}
                      src={infocircle}
                    />
                  </span>
                </Tooltip>
              </div>
            </div>
            <Form.Item
              name="institutionNumber"
              type="institutionNumber"
              className="mb-3"
              rules={[
                {
                  type: 'name',
                  message: 'Please enter a valid Institution Number',
                },
                {
                  required: true,
                  message: 'Please enter your Institution Number',
                },
                {
                  pattern: new RegExp(/^[0-9]+$/),
                  message: 'Only Numbers Required.',
                },
              ]}
            >
              <Input
                disabled={isDisabled}
                type={isDisabled ? 'password' : 'text'}
                style={{
                  height: '50px',
                  border: '1px solid rgba(0,85,134,.5)',
                  color: isDisabled ? 'rgba(0, 0, 0, 0.85)' : undefined,
                }}
                size="large"
                placeholder="Enter Institution Number"
                className="input-field"
                maxLength={3}
                minLength={3}
              />
            </Form.Item>
            <div className="label">
              Account Number
              <div>
                <Tooltip
                  placement="bottomLeft"
                  title="(7-12) numbers indicating account"
                  arrow={false}
                  overlayInnerStyle={{
                    color: '#6B6B6B',
                    backgroundColor: '#fff',
                    width: '250px',
                    fontSize: '12px',
                    fontWeight: '400',
                  }}
                  overlayStyle={{
                    color: '#fff',
                  }}
                >
                  <span className="infocircle">
                    <img
                      style={{ width: '30px', height: '18px', marginLeft: '5px', cursor: 'pointer' }}
                      src={infocircle}
                    />
                  </span>
                </Tooltip>
              </div>
            </div>
            <Form.Item
              name="accountNumber"
              type="accountNumber"
              className="mb-3"
              rules={[
                {
                  type: 'name',
                  message: 'Please enter a valid Account Number',
                },
                {
                  required: true,
                  message: 'Please enter your Account Number',
                },
                {
                  pattern: new RegExp(/^[0-9]+$/),
                  message: 'Only Numbers Required.',
                },
              ]}
            >
              <Input
                disabled={isDisabled}
                type={isDisabled ? 'password' : 'text'}
                style={{
                  height: '50px',
                  border: '1px solid rgba(0,85,134,.5)',
                  color: isDisabled ? 'rgba(0, 0, 0, 0.85)' : undefined,
                }}
                size="large"
                placeholder="Enter Account Number"
                className="input-field"
                maxLength={12}
                minLength={7}
              />
            </Form.Item>
            <div className="label">
              Transit Number
              <div>
                <Tooltip
                  placement="bottomLeft"
                  title="(5) numbers indicating bank branch"
                  arrow={false}
                  overlayInnerStyle={{
                    color: '#6B6B6B',
                    backgroundColor: '#fff',
                    width: '250px',
                    fontSize: '12px',
                    fontWeight: '400',
                  }}
                  overlayStyle={{
                    color: '#fff',
                  }}
                >
                  <span className="infocircle">
                    <img
                      style={{ width: '30px', height: '18px', marginLeft: '5px', cursor: 'pointer' }}
                      src={infocircle}
                    />
                  </span>
                </Tooltip>
              </div>
            </div>
            <Form.Item
              name="transitNumber"
              type="transitNumber"
              className="mb-3"
              rules={[
                {
                  type: 'name',
                  message: 'Please enter a valid Transit Number',
                },
                {
                  required: true,
                  message: 'Please enter your Transit Number',
                },
                {
                  pattern: new RegExp(/^[0-9]+$/),
                  message: 'Only Numbers Required.',
                },
              ]}
            >
              <Input
                disabled={isDisabled}
                type={isDisabled ? 'password' : 'text'}
                style={{
                  height: '50px',
                  border: '1px solid rgba(0,85,134,.5)',
                  color: isDisabled ? 'rgba(0, 0, 0, 0.85)' : undefined,
                }}
                size="large"
                placeholder="Enter Transit Number"
                className="input-field"
                maxLength={5}
                minLength={5}
              />
            </Form.Item>
            <Button
              htmlType="submit"
              className={disabled ? 'payment_continue_btn_disabled' : 'payment_continue_btn'}
              type="primary"
              size="large"
              disabled={disabled || isDisabled}
            >
              {heading ? 'Save' : 'Continue'}
            </Button>
          </Form>
        </SpinWrapper>
      </div>
    </div>
  );
};

BankDetail.propTypes = {
  setPageCount: PropTypes.func,
  save: PropTypes.func,
};

export default BankDetail;
