import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TextEditor = ({ disabled, value, setValue }) => {
  const modules = {
    toolbar: [
      [{ header: [3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: ["right", "center", "justify"] }],
      ["link"],
    ]
  }
  return <ReactQuill modules={modules} readOnly={disabled} theme="snow" value={value} onChange={setValue} />;
}

export default TextEditor;