export const columns = [
  {
    title: "Customer Name",
    dataIndex: "name",
    width: "22%",
    height: '100px'
  },
  {
    title: "Customer Email",
    dataIndex: "email",
    width: "30%",
    height: '100px'
  },
  {
    title: "Subscription Package",
    dataIndex: "package",
    width: "21%",
    height: '100px'
  },
  {
    title: "Customer Since",
    dataIndex: "since",
    width: "17%",
    height: '100px'
  },
  {
    title: "Status",
    dataIndex: "status",
    filters: [
      {
        text: "Active",
        value: "active"
      },
      {
        text: "Inactive",
        value: "inactive"
      }
    ],
    filterMultiple: false,
    onFilter: (value, record) => {
      console.log(value, record, record.status.startsWith(value));
      return record.status.startsWith(value)
    },
    width: "10%",
    height: '100px'
  }
];

export const data = [
  {
    key: "1",
    name: "John Brown",
    email: "janecooper@gmail.com",
    package: "Happy Meal Club",
    since: "2/19/2023",
    status: "inactive",
  },
  {
    key: "2",
    name: "Jim Green",
    email: "janecooper@gmail.com",
    package: "Happy Meal Club",
    since: "2/19/2023",
    status: "active",
  },
  {
    key: "3",
    name: "Joe Black",
    email: "janecooper@gmail.com",
    package: "Happy Meal Club",
    since: "2/19/2023",
    status: "inactive",
  },
  {
    key: "4",
    name: "Jim Red",
    email: "janecooper@gmail.com",
    package: "Happy Meal Club",
    since: "2/19/2023",
    status: "active",
  },
  {
    key: "5",
    name: "John Brown",
    email: "janecooper@gmail.com",
    package: "Happy Meal Club",
    since: "2/19/2023",
    status: "inactive",
  },
  {
    key: "6",
    name: "Jim Green",
    email: "janecooper@gmail.com",
    package: "Happy Meal Club",
    since: "2/19/2023",
    status: "active",
  },
  {
    key: "7",
    name: "Joe Black",
    email: "janecooper@gmail.com",
    package: "Happy Meal Club",
    since: "2/19/2023",
    status: "inactive",
  },
  {
    key: "8",
    name: "Jim Red",
    email: "janecooper@gmail.com",
    package: "Happy Meal Club",
    since: "2/19/2023",
    status: "active",
  },
  {
    key: "9",
    name: "Jim Green",
    email: "janecooper@gmail.com",
    package: "Happy Meal Club",
    since: "2/19/2023",
    status: "active",
  },
  {
    key: "10",
    name: "Joe Black",
    email: "janecooper@gmail.com",
    package: "Happy Meal Club",
    since: "2/19/2023",
    status: "inactive",
  },
  {
    key: "11",
    name: "Jim Red",
    email: "janecooper@gmail.com",
    package: "Happy Meal Club",
    since: "2/19/2023",
    status: "active",
  },
  {
    key: "12",
    name: "Jim Red",
    email: "janecooper@gmail.com",
    package: "Happy Meal Club",
    since: "2/19/2023",
    status: "active",
  }
];