import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import circlemem from '../../assets/images/circlemem.svg';
import arrow from '../../assets/images/arrow-right.svg';

const MembershipCard = ({ item, setDetail, setOpen, button, hideReadMore }) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <div className="membership position-relative">
      <div className="header">
        <div className="heading">{item.title}</div>
        <div className={item.isActive ? 'status active' : 'status'}>
          <span /> {item.isActive ? 'Active' : 'Inactive'}
        </div>
      </div>
      <div className="price">${`${item.amount}/${item.interval}`}</div>
      <div className="detail">{item.isSubscription ? 'Subscription Membership' : 'Wallet Membership'}</div>
      <div className="detail">{item?.description && ReactHtmlParser(item?.description)}</div>
      {!hideReadMore && (
        <div className="showMore-wrapper">
          {item?.description?.length > 250 && (
            <div className="show" onClick={() => setDetail(item)}>
              {showMore ? 'Read Less' : 'Read More'}
            </div>
          )}
        </div>
      )}
      <div className="d-flex w-100 justify-content-between">
        <div className="viewcircle">
          {button && (
            <button onClick={() => setDetail(item)}>
              {/* View <span><ArrowRightOutlined className='icon' style={{fontWeight:"900",color:"#fff"}} /></span> */}
              {button ?? 'View'}
              <span>
                <img src={arrow} className="icon" style={{ marginLeft: '4px', marginTop: '-2px' }} />
              </span>
            </button>
          )}
        </div>
      </div>
      <img src={circlemem} className="circleimg1" />
      <img src={circlemem} className="circleimg2" />
      <Modal
        centered
        width={600}
        header={null}
        footer={null}
        open={showMore}
        className="membership-modal"
        onCancel={() => {
          setShowMore(!showMore);
        }}
      >
        <div className="description-card">{item?.description && ReactHtmlParser(item?.description)}</div>
      </Modal>
      <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', zIndex: 1000 }}>
        <button onClick={() => setDetail(item)}>View Package</button>
      </div>
    </div>
  );
};

export default MembershipCard;
