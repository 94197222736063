import React, { useEffect, useState } from "react";
import {Form, Row, Col, Input, Button} from 'antd';
import { message, Tooltip } from "antd";
import { useDispatch } from "react-redux";
import { setLoader } from '@/store/loginslice/LoaderSlice'
import { CardNumberElement, CardExpiryElement, CardCvcElement, useElements, useStripe } from '@stripe/react-stripe-js';
import '../style.less'
import { addCustomerCard, getCustomerProfile } from "@/api/customerapi";

const PaymentDetails = ({data, helpers: {setCurrentStep, setUser, setPaymentMethod}}) => {
  const [disabled, setDisabled] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({
    name: '',
    cardNumber: '',
    expiryDate: '',
    cvv: ''
  })
  const [checkfields, setCheckfields] = useState({
    cardnumber: false,
    expiry: false,
    cvv: false,
    name: false

  })

  useEffect(() => {
    if (elements) {
      elements?.getElement(CardNumberElement).clear();
      elements?.getElement(CardExpiryElement).clear();
      elements?.getElement(CardCvcElement).clear();
      form.resetFields()
    }
  }, [])

  useEffect(() => {
    if (checkfields.cardnumber === "true" && checkfields.expiry === "true" && checkfields.cvv === "true" && checkfields.name === "true") {
      setDisabled(false)
    } else setDisabled(true)
  }, [checkfields])

  const handleFormChange = () => {
    const hasErrors =
      !form.isFieldsTouched(true) || form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    setDisabled(hasErrors);
  }

  const handleSubmit = async () => {
    //setCurrentStep(3)
    // TODO: Add current User ID to request (userId) and send to /add-customer-card
    try {
      if (elements == null) return;
      dispatch(setLoader(true))
      // setLoading(true);
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
      });

      if (error) {
        message.error(error.message)
        dispatch(setLoader(false));
      }

      if (paymentMethod?.card?.funding !== 'credit' && paymentMethod?.card?.funding !== 'debit') {
        dispatch(setLoader(false));
        message.error('Only credit card or debit card allowed')
        return;
      }

      const payload = {
        name: formData?.name,
        brand: paymentMethod?.card?.brand,
        last4: paymentMethod?.card?.last4,
        funding: paymentMethod?.card.funding,
        expMonth: paymentMethod?.card?.exp_month?.toString(),
        expYear: paymentMethod?.card?.exp_year?.toString(),
        paymentMethodId: paymentMethod?.id,
        userId: data.user?._id
      }

      const res = await addCustomerCard(payload);

      if (res.data?.status === 200) {
        const userRes = await getCustomerProfile(data.user._id)

        if (userRes.data?.status === 200) {
          dispatch(setLoader(false));
          setUser(userRes.data?.output?.userData)
          setPaymentMethod(res.data?.output?.cardData)
          setCurrentStep(2)
        }
      }
    } catch (error) {

      dispatch(setLoader(false));
      message.error(error?.response?.data?.error?.message);
    }
  }

  const handleChange = async (event, cardinput) => {
    const e = await event;
    if (cardinput == "name") {
      setFormData({
        ...formData, [e.target.name]: e.target.value
      });
      if (e.target.value.length > 1) {
        setCheckfields({ ...checkfields, name: "true" })
      }
      else {
        setCheckfields({ ...checkfields, name: "false" })
      }

    }

    if (e?.complete === true) {
      if (cardinput == 'cardnumber')
        setCheckfields({ ...checkfields, cardnumber: "true" })
      if (cardinput == 'expiry')
        setCheckfields({ ...checkfields, expiry: "true" })
      if (cardinput == 'cvv')
        setCheckfields({ ...checkfields, cvv: "true" })
    } else if (e?.error) {
      if (cardinput == 'cardnumber')
        setCheckfields({ ...checkfields, cardnumber: "false" })
      if (cardinput == 'expiry')
        setCheckfields({ ...checkfields, expiry: "false" })
      if (cardinput == 'cvv')
        setCheckfields({ ...checkfields, cvv: "false" })
    } else if (e?.empty === false && e?.error === undefined) {
      if (cardinput == 'cardnumber')
        setCheckfields({ ...checkfields, cardnumber: "false" })
      if (cardinput == 'expiry')
        setCheckfields({ ...checkfields, expiry: "false" })
      if (cardinput == 'cvv')
        setCheckfields({ ...checkfields, cvv: "false" })
    }
  }

  return (
    <div className={"center-container"}>
      <div className="welcome-container mt-4">
        <Row>
          <Col span={12}>
            <Form
              onFieldsChange={handleFormChange}
              onFinish={handleSubmit}
              autoComplete="off"
              layout="vertical"
              form={form}
            >
              <div>

                <div className='label'>Name on card</div>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      type: 'name',
                      message: 'Please enter a valid card name',
                    },
                    {
                      required: true,
                      message: 'Please enter your card name',
                    },
                    {
                      pattern: new RegExp(/^[A-Za-z ]+$/),
                      message:
                    "Field does not accept numbers or special characters.",
                    },
                  ]}
                >
                  <Input
                    name="name"
                    className="input-text-field"
                    placeholder="Name on Card"
                    type="text"
                    // onChange={changeFormData}
                    onChange={(e) => handleChange(e, "name")}
                    value={formData?.name}
                    //pattern='[A-Za-z ]'
                    size="large"
                    maxLength={32}
                    minLength={3}
                  />
                </Form.Item>
              </div>
              <div>
                <div className='label'>Credit Card Number</div>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your card number',
                    }
                  ]}
                >
                  <CardNumberElement
                    options={{
                      showIcon: true,
                      iconStyle: 'solid',
                      placeholder: 'Enter Credit Number',
                      style: {
                        empty: {
                          '::placeholder': {
                            color: '#6B6B6B',
                          },
                        },
                        base: {
                          iconColor: '#111111',
                          color: '#111111',
                          fontWeight: '500',
                          fontSize: '16px',
                          fontFamily: 'Poppins, Open Sans, Segoe UI, sans-serif',
                          fontSmoothing: 'antialiased',
                          ':-webkit-autofill': {
                            color: '#111111',
                          },
                        },
                        invalid: {
                          iconColor: 'red',
                          color: 'red',
                          fontWeight: '500',
                          fontSize: '16px',
                          fontFamily: 'Poppins, Open Sans, Segoe UI, sans-serif',
                        },
                      },
                    }}
                    className='card-number'
                    onChange={(e) => handleChange(e, "cardnumber")}
                  />
                </Form.Item>
              </div>
              <div>
                <Row className="card-element d-flex justify-content-between">
                  <Col xs={24} md={11} lg={11} className='mb-sm-3 mb-md-0'>
                    <div className='label'>Expiry Date</div>
                    <Form.Item>
                      <CardExpiryElement
                        className='card-expiry'
                        options={{
                          placeholder: 'MM/YY',
                          style: {
                            empty: {
                              '::placeholder': {
                                color: '#6B6B6B',
                              },
                            },
                            base: {
                              iconColor: '#111111',
                              color: '#111111',
                              fontWeight: '500',
                              fontSize: '16px',
                              fontFamily: 'Poppins, Open Sans, Segoe UI, sans-serif',
                              fontSmoothing: 'antialiased',
                              ':-webkit-autofill': {
                                color: '#111111',
                              },
                            },
                            invalid: {
                              iconColor: 'red',
                              color: 'red',
                              fontWeight: '500',
                              fontSize: '16px',
                              fontFamily: 'Poppins, Open Sans, Segoe UI, sans-serif',
                            },
                          },
                        }}
                        onChange={(e) => handleChange(e, "expiry")}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={11} lg={11} className='card-cvv-wrapper'>
                    <div className='label'>CVV</div>
                    <Form.Item>
                      <CardCvcElement
                        className='card-cvv'
                        type="password"
                        options={{
                          placeholder: 'Enter CVV',
                          style: {
                            empty: {
                              '::placeholder': {
                                color: '#6B6B6B',
                              },
                            },
                            base: {
                              iconColor: '#111111',
                              color: '#111111',
                              fontWeight: '500',
                              fontSize: '16px',
                              fontFamily: 'Poppins, Open Sans, Segoe UI, sans-serif',
                              fontSmoothing: 'antialiased',
                              ':-webkit-autofill': {
                                color: '#111111',
                              },
                            },
                            invalid: {
                              iconColor: 'red',
                              color: 'red',
                              fontWeight: '500',
                              fontSize: '16px',
                              fontFamily: 'Poppins, Open Sans, Segoe UI, sans-serif',
                            },
                          },
                        }}
                        onChange={(e) => handleChange(e, "cvv")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <Button
                htmlType="submit"
                className="mt-3"
                type="primary"
                size="large"
                block
                disabled={disabled}
              >
                Continue
              </Button>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default PaymentDetails
