const ScanIcon = ({ val }) => {
  return (
      <>
      {!val?
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M21.75 11.4551H0.75C0.336 11.4551 0 11.1191 0 10.7051C0 10.2911 0.336 9.95508 0.75 9.95508H21.75C22.164 9.95508 22.5 10.2911 22.5 10.7051C22.5 11.1191 22.164 11.4551 21.75 11.4551Z" fill={val ? "#003B5B" : "white"} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M19.8794 7.245C19.4654 7.245 19.1294 6.909 19.1294 6.495V4.981C19.1294 3.338 17.7914 2 16.1464 2H14.9414C14.5274 2 14.1914 1.664 14.1914 1.25C14.1914 0.836 14.5274 0.5 14.9414 0.5H16.1464C18.6184 0.5 20.6294 2.511 20.6294 4.981V6.495C20.6294 6.909 20.2934 7.245 19.8794 7.245Z" fill={val ? "#003B5B" : "white"} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.62109 7.245C2.20709 7.245 1.87109 6.909 1.87109 6.495V4.981C1.87109 2.511 3.88209 0.5 6.35409 0.5H7.59009C8.00409 0.5 8.34009 0.836 8.34009 1.25C8.34009 1.664 8.00409 2 7.59009 2H6.35409C4.70909 2 3.37109 3.338 3.37109 4.981V6.495C3.37109 6.909 3.03509 7.245 2.62109 7.245Z" fill={val ? "#003B5B" : "white"} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.1464 19.2601H14.9414C14.5274 19.2601 14.1914 18.9241 14.1914 18.5101C14.1914 18.0961 14.5274 17.7601 14.9414 17.7601H16.1464C17.7914 17.7601 19.1294 16.4221 19.1294 14.7781V10.7031C19.1294 10.2891 19.4654 9.95312 19.8794 9.95312C20.2934 9.95312 20.6294 10.2891 20.6294 10.7031V14.7781C20.6294 17.2491 18.6184 19.2601 16.1464 19.2601Z" fill={val ? "#003B5B" : "white"} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.59009 19.2601H6.35409C3.88209 19.2601 1.87109 17.2491 1.87109 14.7781V10.7031C1.87109 10.2891 2.20709 9.95312 2.62109 9.95312C3.03509 9.95312 3.37109 10.2891 3.37109 10.7031V14.7781C3.37109 16.4221 4.70909 17.7601 6.35409 17.7601H7.59009C8.00409 17.7601 8.34009 18.0961 8.34009 18.5101C8.34009 18.9241 8.00409 19.2601 7.59009 19.2601Z" fill={val ? "#003B5B" : "white"} />
    </svg>:
    <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.42822 0.5C7.84822 0.5 8.18922 0.834385 8.18922 1.24722C8.18922 1.65907 7.84922 1.99345 7.42822 1.99345L5.79922 1.99444C4.44722 1.9964 3.34722 3.07604 3.34722 4.40279V6.26201C3.34722 6.67386 3.00522 7.00923 2.58522 7.00923C2.16522 7.00923 1.82422 6.67386 1.82422 6.26201V4.40279C1.82422 2.25331 3.60722 0.502942 5.79822 0.500981L7.42722 0.5H7.42822ZM14.6008 0.500488H16.1938C18.3908 0.500488 20.1768 2.25184 20.1768 4.40622V6.2625C20.1768 6.67435 19.8368 7.00971 19.4158 7.00971C18.9958 7.00971 18.6548 6.67435 18.6548 6.2625V4.40622C18.6548 3.07555 17.5508 1.99296 16.1938 1.99296H14.6008C14.1808 1.99296 13.8398 1.65956 13.8398 1.24771C13.8398 0.834873 14.1808 0.500488 14.6008 0.500488ZM13.7618 4.24268H8.23784C6.97384 4.25542 5.95784 5.26838 5.96884 6.50884V7.75421C5.97184 7.89933 6.09084 8.01799 6.23884 8.02289H15.7588C15.9078 8.01897 16.0268 7.90032 16.0318 7.75421V6.50884C16.0328 5.9097 15.7968 5.33408 15.3708 4.90556C14.9478 4.48096 14.3668 4.24268 13.7618 4.24268ZM0.762 9.54123H21.239C21.659 9.54123 22 9.87562 22 10.2885C22 10.7003 21.659 11.0337 21.239 11.0337H20.177V14.5933C20.177 16.7486 18.39 18.5 16.194 18.5H14.601C14.18 18.5 13.839 18.1656 13.839 17.7528C13.839 17.3409 14.18 17.0065 14.601 17.0065H16.194C17.551 17.0065 18.655 15.9249 18.655 14.5933V11.0337H16.032V12.0251C16.042 13.2656 15.027 14.2795 13.762 14.2913H8.238C6.974 14.2795 5.959 13.2656 5.969 12.0251V11.0337H3.346V14.5972C3.346 15.924 4.447 17.0036 5.8 17.0056L7.428 17.0065C7.848 17.0065 8.189 17.3409 8.189 17.7528C8.188 18.1656 7.848 18.5 7.427 18.5L5.798 18.499C3.607 18.4971 1.823 16.7467 1.823 14.5972V11.0337H0.762C0.342 11.0337 0 10.7003 0 10.2885C0 9.87562 0.342 9.54123 0.762 9.54123Z" fill="#003B5B"/>
</svg>
      }
    </>
    
  )
}

export default ScanIcon;