import instance from '../config/axios';
export async function addBonus(data) {
  try
  {
    const val= instance.post(`${process.env.REACT_APP_BASE_URL}user/add-bonus`, data);
    return val;
  }
  catch(err)
  {
    console.log(err)
  }
}

export async function updateCustomerDetails(data) {
  try
  {
    const val= instance.post(`${process.env.REACT_APP_BASE_URL}user/update-customer-details`, data);
    return val;
  }
  catch(err)
  {
    console.log(err)
  }
}

export async function makePaymentApi(data) {
  return instance.post(`${process.env.REACT_APP_BASE_URL}user/make-payment`, data)
}
export async function pauseMebershipApi(data) {
  try
  {
    const val= instance.post(`${process.env.REACT_APP_BASE_URL}user/pause-user-membership`, {orderIds:data});
    return val;
  }
  catch(err)
  {
    console.log(err)
  }

}

export async function customerSignup(data) {
  return instance.post(`${process.env.REACT_APP_BASE_URL}user/signup`, data)
}

export async function createCustomerProfile(data) {
  return instance.post(`${process.env.REACT_APP_BASE_URL}user/create-customer-profile`, data)
}

export async function getCustomerProfile(id) {
  return instance.post(`${process.env.REACT_APP_BASE_URL}user/get-customer-profile`, {
    userId: id
  })
}

export async function getCustomerProfileByEmail(email) {
  return instance.get(`${process.env.REACT_APP_BASE_URL}user/get-customer-profile-by-email?email=${email}`)
}

export async function addCustomerCard(data) {
  return instance.post(`${process.env.REACT_APP_BASE_URL}user/add-customer-card`, data)
}

export async function buyCustomerMembership(data) {
  return instance.post(`${process.env.REACT_APP_BASE_URL}user/buy-customer-membership`, data)
}

export async function sendWelcomeEmail(data) {
  return instance.post(`${process.env.REACT_APP_BASE_URL}user/send-welcome-email`, data)
}
