import React from 'react'
import dayjs from 'dayjs';
import './notificationcard.less';
import isYesterday from 'dayjs/plugin/isYesterday';
import isToday from 'dayjs/plugin/isToday';
import isBetween from 'dayjs/plugin/isBetween'; // Import the 'isBetween' plugin
import weekday from 'dayjs/plugin/weekday'; // Import the 'weekday' plugin
dayjs.extend(isBetween); // Extend Day.js with the 'isBetween' plugin
dayjs.extend(weekday); 
dayjs.extend(isYesterday); // Import the 'isToday' plugin
dayjs.extend(isToday);
import { useEffect,useState } from 'react';
const NotificationCard = ({item}) => {
  const [time,setTime]=useState();
  const weekdays=["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
  const callTimeFunc=()=>{
    const isDateYesterday = dayjs(item.createdAt).isYesterday();
    const isDateToday = dayjs(item.createdAt).isToday();
    const weekStart = dayjs().subtract(1, 'week').startOf('day');
    const weekEnd = dayjs().endOf('day');
  const isWithinWeek = dayjs(item.createdAt).isBetween(weekStart, weekEnd, null, '[]');
  const weekdayText = dayjs(item.createdAt).weekday();
    if(isDateToday)
    {
      setTime(dayjs(item.createdAt).format("hh:mm A"));
    }
    else if(isDateYesterday)
    {
      setTime("yesterday")
    }
    else if(isWithinWeek)
    {
      setTime(`${weekdays[weekdayText]}`)
    }
    else
    {
      setTime(dayjs(item.createdAt).format("MM/DD/YYYY"))
    }
  }

  useEffect(()=>{
    callTimeFunc();
  },[])
  return (
    <>
           {item.isRead?<div className="singlenotifcation">
            <div className="notification">
           {item.message}
            </div>
            <div className="time">
            {time}
            </div>
            </div>:
            <div className="singlenot">
            <div className="notificationblue">
           {item.message}
            </div>
            <div className="time">
            {time}
            </div>
            </div>
            }
               
              </>)
}

export default NotificationCard