import instance from '../config/axios';
export async function createnewsfeedapi(values) {
  try {
    const val = instance.post(`${process.env.REACT_APP_BASE_URL}newsfeed/create`, values);
    return val;
  }
  catch (err) {
    return err;
    // console.log(err);
  }
}
export async function getnewsfeedapi(values) {
  try {
    const val = await instance.post(`${process.env.REACT_APP_BASE_URL}newsfeed/list`, values)
    return val;
  }
  catch (err) {
    return err;
  }
}
export async function editnewsfeedapi(values) {
  try {
    const val = await instance.put(`${process.env.REACT_APP_BASE_URL}newsfeed/edit`, values)
    return val;
  }
  catch (err) {
    return err;
    //console.log(err,"....err")
  }
}
export async function deletenewsfeedapi(payload) {
  return instance.delete(`${process.env.REACT_APP_BASE_URL}newsfeed/delete/${payload.id}`)
}