import React, { useState } from "react";
import Card from "antd/es/card";
import bnkdbluelogo from '../../assets/images/bnkdbluelogo.png'
import { useNavigate } from "react-router-dom";
import BankDetail from "../../pages/paymentdetail/BankDetail";
import ModalComponent from "../../pages/paymentdetail/Modal";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import '../../pages/paymentdetail/style.less';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const PaymentDetail = ({ heading }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className={location.pathname === '/settings' ? 'payment-wrapper h-100' : 'payment-wrapper'}>
      <div className={location.pathname === '/settings' ? 'payment-detail' : 'payment-detail m-auto'}>
        {location.pathname === '/settings' ? (
          <div className="setting-page mt-3">
            <ModalComponent showModal={showModal} setShowModal={setShowModal} />

            <Elements stripe={stripePromise}>
              <BankDetail heading={heading} setShowModal={setShowModal} />
            </Elements>
          </div>
        ) : (
          <div className="cards">
            <Card>
              <div className="bnkdlogo">
                <img src={bnkdbluelogo} alt="bnkd logo" style={{ width: "170px", height: "40px" }} />
              </div>

              <ModalComponent showModal={showModal} setShowModal={setShowModal} redirect />

              <Elements stripe={stripePromise}>
                <BankDetail heading={heading} setShowModal={setShowModal} />
              </Elements>
            </Card>
          </div>
        )}
      </div>
    </div>
  )
}

export default PaymentDetail;