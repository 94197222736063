import Modal from "antd/es/modal/Modal";
import './styles.less'
import crosscircleicon from '../../assets/images/crosscirlce.png'
import { Input, Button, Tooltip } from "antd";
import { useState, useEffect } from "react";
import { addBonus, addBonusForCustomer, updateCustomerDetails } from '../../api/customerapi';
import { useSelector } from "react-redux";
import dummyprofile from '../../assets/images/dummyprofile.png';
import Form from "antd/es/form";
import {message} from 'antd';
import { convertNumber } from "../membershippackage/helper";
import infocircle from '../../assets/images/Info-Circle.svg'

const UpdateBonusModal = ({ addBonusModal, setAddBonusModal, updateModal, setUpdateModal, selectedId, viewCustomerApi, custInfo, amount }) => {
  const customerId = useSelector((state) => state.customer.customerId)
  const [bonusBal, setBonusBal] = useState(0);
  const [values, setValues] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [detailsd, setDetailsd] = useState(false)
  const [form] = Form.useForm();

  useEffect(() => {
    setValues({
      email: custInfo.email,
      amount: amount,
      firstName: custInfo.firstName,
      lastName: custInfo.lastName,
      invoiceId: ''
    })
    form.setFieldValue('email', custInfo.email)
    form.setFieldValue('firstName', custInfo.firstName)
    form.setFieldValue('lastName', custInfo.lastName)
  }, [custInfo])

  const handleFormChange = () => {
    const hasErrors =
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    setDetailsd(hasErrors);
  }

  const updateBonus = async () => {
    setAddBonusModal(false);
    //const val=Number(amount)+Number(bonusBal);
    //form.setFieldValue('amount', val.toFixed(2));
    //setValues({...values,amount:val.toFixed(2)})
    callApiAddBonus()
  }

  const updateDetails = async () => {
    setUpdateModal(false);
    callApiUpdateDetails()
  }

  const callApiAddBonus = async () => {
    const response = await addBonus({ customerId: customerId || selectedId, amount: values.amount, invoiceId: values.invoiceId });
    if (response.data?.status === 200) {
      viewCustomerApi(customerId || selectedId);
    }
    else
    {
      message.error("Something went wrong please try again")
    }
  }

  const callApiUpdateDetails = async () => {
    const response = await updateCustomerDetails({ customerId: customerId || selectedId, email:values.email,firstName:values.firstName,lastName:values.lastName });
    if (response.data?.status === 200) {
      viewCustomerApi(customerId || selectedId);
    }
    else
    {
      message.error("Something went wrong please try again")
    }
  }

  const handleChange = (e) => {
    if (e.target.value > 0) {
      setDisabled(false)
    }
    else {
      setDisabled(true)
    }
  }



  const handleAddBonus=()=>{
    setAddBonusModal(true);
    setUpdateModal(false);
    setValues({...values,amount:0})
  }

  return (
    <>
      <Modal
        centered
        width={450}
        header={null}
        footer={null}
        className="addbonusmodal"
        closable={false}
        open={addBonusModal}
      >
        <div className="crossicon" onClick={() => { setAddBonusModal(false) }}><img style={{width:"25px",height:"25px"}} src={crosscircleicon} /></div>
        <div className="addbonusheader" >Add Bonus
        </div>


        <div className="addbonusinputinfo">
          <div className="addbonusinput" style={{marginBottom:"8px"}}>Bonus Amount
          </div>
          <span className="searchicon" >$</span>
          <Input type="number"   style={{height:"45px",width:"100%"}} min={1} className="input-field" onChange={(e) => { setValues({...values, amount: e.target.value}); handleChange(e) }} />
        </div>
        <div className="addbonusinputinfo">
          <div className="addbonusinput" style={{marginBottom:"8px"}}>Reason
            <Tooltip placement="bottomLeft" title="Optional" arrow={false} overlayInnerStyle={{ color: "#6B6B6B", backgroundColor: "#fff", width: "5vw", fontSize: "12px" }} overlayStyle={{
              color: "#fff"
            }}>
              <span className="infocircle">
                <img style={{ width: "15px", height: "15px", marginLeft: "5px", cursor: "pointer" }} src={infocircle} />
              </span>
            </Tooltip>
          </div>

          <Input size="large"  className="input-field" onChange={(e) => setValues({...values, invoiceId: e.target.value})} />
        </div>
        <Button type="submit" onClick={() => { updateBonus() }} className={!disabled ? "addbonusbtn" : "addbonusbtndisabled"} disabled={disabled}>Add Bonus</Button>
      </Modal>
      <Modal
        centered
        width={550}
        header={null}
        footer={null}
        className="updatedetailsmodal"
        closable={false}
        open={updateModal}
      >
        <div className="crossicon" onClick={() => { setUpdateModal(false) }}><img style={{width:"25px",height:"25px"}} src={crosscircleicon} /></div>
        <div className="updateinfoheader" >Customer Details
        </div>

        <div className="updateuserlogo d-flex m-auto">
          <img className="userimg"  src={custInfo.profilePicture ? custInfo.profilePicture : dummyprofile} />
        </div>
        <Form
          name="name-form"
          initialValues={{ remember: true }}
          autoComplete="off"
          layout="vertical"
          //  onFinish={onFinish}
          // validateTrigger='onSubmit'
          onFieldsChange={handleFormChange}
          form={form}
        >
          <div className="updatebalance">
            <div className="updateheader">  Total Balance
            </div>
            <Form.Item
              // name="amount"
              type="number"
              className="mb-3"
              rules={[
                {
                  required: true,
                  message: 'Please enter your amount',
                },
                {
                  pattern: new RegExp(/^[1-9][0-9]*$/),
                  message:
                    "It should be number and must not start with zero",
                },
              ]}
            >
              <Input type="text" disabled className="input-field" style={{color:"black"}}value={`$${convertNumber(values.amount)}`} onChange={(e) => { setValues({ ...values, amount: e.target.value }) }} />
            </Form.Item>
            <Button className="addbonus" onClick={() => {handleAddBonus()  }}>Add Bonus</Button>
          </div>
          <div className="updatebalance">
            <div className="updateheader">First Name
            </div>
            <Form.Item
              name="firstName"
              type="text"
              className="mb-3"
              rules={[
                {
                  type: 'text',
                  message: 'Please enter a valid name',
                },
                {
                  required: true,
                  message: 'Please enter your name',
                },
              ]}
              initialValue={values.firstName}
            >
              <Input className="input-field" value={values.firstName} onChange={(e) => { setValues({ ...values, firstName: e.target.value }) }} />
            </Form.Item>
          </div>
          <div className="updatebalance">
            <div className="updateheader">Last Name
            </div>
            <Form.Item
              name="lastName"
              type="text"
              className="mb-3"
              rules={[
                {
                  type: 'text',
                  message: 'Please enter a valid name',
                },
                {
                  required: true,
                  message: 'Please enter your name',
                },
              ]}
              initialValue={values.lastName}
            >
              <Input className="input-field" value={values.lastName} onChange={(e) => { setValues({ ...values, lastName: e.target.value }) }} />
            </Form.Item>
          </div>
          <div className="updatebalance">
            <div className="updateheader">Email
            </div>
            <Form.Item
              name="email"
              type="email"
              className="mb-3"
              rules={[
                {
                  type: 'email',
                  message: 'Please enter a valid E-mail.',
                },
                {
                  required: true,
                  message: 'Please enter your E-mail',
                },
              ]}
            >
              <Input className="input-field" value={values.email} onChange={(e) => { setValues({ ...values, email: e.target.value }) }} />
            </Form.Item>.
          </div>
          <Button htmlType="submit" className="updatebtn" disabled={detailsd} onClick={() => { updateDetails() }}>Save</Button>
        </Form>
      </Modal>
    </>
  )
}

export default UpdateBonusModal;
