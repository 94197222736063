import './style.less';
import { useState } from 'react';
import bnkdwhite from '../assets/images/bnkdwhite.png';
import { Menu, Button } from 'antd';
import Modal from 'antd/es/modal';
import logoutpop from '../assets/images/logoutpop.png';
import { useNavigate } from 'react-router-dom';
import CustomerIcon from '../components/menusvg/CusotmerIcon';
import ScanIcon from './menusvg/ScanIcon';
import NewsFeedIcon from './menusvg/NewsFeedIcon';
import ReportsIcon from './menusvg/ReportsIcon';
import SubscriptionIcon from './menusvg/SubscriptionIcon';
import SettingsIcon from './menusvg/SettingsIcon';
import LogoutIcon from './menusvg/LogoutIcon';
import { useDispatch, useSelector } from 'react-redux';
import { addCount } from '../store/loginslice/LoginSlice';
import { clearData } from '../store/loginslice/LoginSlice';
import { setIsFiltered } from '../store/loginslice/mermberShipSlice';
import { MenuFoldOutlined, CloseOutlined } from '@ant-design/icons';

const Sidebar = () => {
  const [handleLogoutModal, setHandleLogoutModal] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const count = useSelector((state) => state?.user?.sidebar?.count);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    setHandleLogoutModal(false);
    localStorage.clear();
    dispatch(addCount(3));
    navigate('/');
    dispatch(clearData());
  };

  const handleLogoutClose = () => {
    setHandleLogoutModal(false);
  };

  const navigateRoute = (url) => {
    navigate(url);
    if (window.innerWidth <= 768) {
      setIsSidebarOpen(false);
    }
  };

  return (
    <>
      <div className="menu-icon" onClick={() => setIsSidebarOpen(true)}>
        <MenuFoldOutlined />
      </div>
      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <div className="close-icon" onClick={() => setIsSidebarOpen(false)}>
          <CloseOutlined />
        </div>
        <div className="bnkdimg">
          <img src={bnkdwhite} alt="bnkdimg" style={{ width: '100%', height: '100%' }} />
        </div>
        <Menu
          className="ant-menu-dark sidebarmenu"
          defaultSelectedKeys={[`${count}`]}
          defaultOpenKeys={['sub1']}
          theme="dark"
          onClick={(e) => {
            dispatch(addCount(e.key));
            dispatch(setIsFiltered(false));
            if (window.innerWidth <= 768) {
              setIsSidebarOpen(false);
            }
          }}
        >
          <Menu.Item className="customclass" key="1" onClick={() => navigateRoute('/search-customers')}>
            {count == 1 ? <ScanIcon val="true" /> : <ScanIcon value="false" />}
            <span className={count == 1 ? 'sidemenuitem sideweight' : 'sidemenuitem sideweightwhite'}>Customers</span>
          </Menu.Item>
          <Menu.Item className="customclass" key="2" onClick={() => navigateRoute('/subscribed-customers')}>
            {count == 2 ? <CustomerIcon val="true" /> : <CustomerIcon value="false" />}
            <span className={count == 2 ? 'sidemenuitem sideweight' : 'sidemenuitem sideweightwhite'}>
              Subscribed Customers
            </span>
          </Menu.Item>
          <Menu.Item className="customclass" key="3" onClick={() => navigateRoute('/membership-packages')}>
            {count == 3 ? <SubscriptionIcon val="true" /> : <SubscriptionIcon value="false" />}
            <span className={count == 3 ? 'sidemenuitem sideweight' : 'sidemenuitem sideweightwhite'}>
              Membership Packages
            </span>
          </Menu.Item>
          <Menu.Item className="customclass" key="4" onClick={() => navigateRoute('/news-feed')}>
            {count == 4 ? <NewsFeedIcon val="true" /> : <NewsFeedIcon value="false" />}
            <span className={count == 4 ? 'sidemenuitem sideweight' : 'sidemenuitem sideweightwhite'}>News Feed</span>
          </Menu.Item>
          <Menu.Item className="customclass" key="5" onClick={() => navigateRoute('/reports')}>
            {count == 5 ? <ReportsIcon val="true" /> : <ReportsIcon value="false" />}
            <span className={count == 5 ? 'sidemenuitem sideweight' : 'sidemenuitem sideweightwhite'}>Reports</span>
          </Menu.Item>
          <Menu.Item key="6" onClick={() => navigateRoute('/settings')} className="customclass">
            {count == 6 ? <SettingsIcon val="true" /> : <SettingsIcon value="false" />}
            <span className={count == 6 ? 'sidemenuitem sideweight' : 'sidemenuitem sideweightwhite'}>Settings</span>
          </Menu.Item>
          <Menu.Item key="7" className="customclass logout-btn" onClick={() => setHandleLogoutModal(true)}>
            {count == 7 ? <LogoutIcon val="true" /> : <LogoutIcon value="false" />}
            <span className={count == 7 ? 'sidemenuitem sideweight' : 'sidemenuitem sideweightwhite'}>Logout</span>
          </Menu.Item>
        </Menu>
      </div>
      <Modal
        centered
        width={390}
        header={null}
        footer={null}
        open={handleLogoutModal}
        className="logoutmodal"
        closable={false}
      >
        <div className="crossImage">
          <img src={logoutpop} className="text-center" />
        </div>
        <div className="logoutdesc">Are you sure you want to Logout?</div>
        <div
          className="confirmButtons"
          style={{ justifyContent: 'space-between', display: 'flex', height: '50px', marginTop: '10px' }}
        >
          <Button className="yesbtn" onClick={handleLogout}>
            Yes
          </Button>
          <Button className="nobtn" onClick={handleLogoutClose}>
            No
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Sidebar;
