import Modal from "antd/es/modal/Modal";
import { useState, useEffect } from 'react'
import './styles.less'
import crosscircleicon from '../../assets/images/crosscirlce.png'
import { Tooltip, Input, Button, InputNumber } from "antd";
import infocircle from '../../assets/images/Info-Circle.svg'
import mediaicon from '../../assets/images/deletemodalicon.png'
import { makePaymentApi } from "../../api/customerapi";
import { message } from 'antd';
import Form from "antd/es/form";
import { io } from 'socket.io-client';


const PaymentModal = ({ openModal, setOpenModal, selectedId, viewCustomerApi, handleModal, setHandleModal }) => {
  const [disabled, setDisabled] = useState(true)
  const [userId, setUserId] = useState();
  const [form] = Form.useForm();
  const [isValidError, setIsValidError] = useState(false);
  const [data, setData] = useState({
    amount: '',
    invoiceId: '',
    retailAmount: ''

  });
  const accessToken = localStorage.getItem("token")
  const socket = io(`${process.env.REACT_APP_SOCKET_URL}?accessToken=${accessToken}`
  );
  useEffect(() => {
    setUserId(selectedId)
  }, [])
  useEffect(() => {
    socket.on('connect', () => {
      // console.log('Connected to the server');
    });
    return () => {
      socket.disconnect();
    };
  }, [])
  const handlePayment = async () => {
    const response = await makePaymentApi({ customerId: selectedId, invoiceId: data.invoiceId, retailAmount: Number(data.retailAmount), amount: Number(data.amount) });
    if (response.data?.status === 200) {
      message.success("Payment Sucessfull");
      const transactionId = response.data.output.transactionData._id;
      socket.emit("sendPaymentDetails", { transactionId: transactionId, customerId: selectedId }, () => { console.log(resp, "emmited") })
      socket.disconnect();
      viewCustomerApi(selectedId)
    }
  }

  const onFinish = (values) => {
    setData(values);
  }

  const handleFormChange = () => {
    if (Number(data.retailAmount) < Number(data.amount)) {
      setIsValidError(true)
    }
    else {
      setIsValidError(false)
    }
    const hasErrors =
      !(data.amount != '' && data.retailAmount != '' && Number(data.retailAmount) >= Number(data.amount)) || form.getFieldsError().filter(({ errors }) => errors.length).length > 0;

    setDisabled(hasErrors);
  }

  return (
    <>
      <Modal
        centered
        width={450}
        header={null}
        footer={null}
        open={openModal}
        className="paymentmodal"
        closable={false}
      >
        <div className="makepaymentinput">
          <div className="crossicon" onClick={() => { setOpenModal(false) }}><img style={{ width: "25px", height: "25px" }} src={crosscircleicon} /></div>
          <Form
            name="name-form"
            initialValues={{ remember: true }}
            autoComplete="off"
            layout="vertical"
            onFinish={onFinish}
            onFieldsChange={handleFormChange}
            form={form}
          >
            <div className="makepayment">
              <div className="makepaymentheading" >Make Payment
              </div>
            </div>
            <div className="makepaymentinput">
              <div className="makepaymentheading" >Total Retail Purchase
                <Tooltip placement="bottomLeft" title="Total in store purchase that the customer is making." arrow={false} overlayInnerStyle={{ color: "#6B6B6B", backgroundColor: "#fff", width: "15vw", fontSize: "12px" }} overlayStyle={{
                  color: "#fff"
                }}>
                  <span className="infocircle">
                    <img style={{ width: "15px", height: "15px", marginLeft: "5px", cursor: "pointer" }} src={infocircle} />
                  </span>
                </Tooltip>
              </div>
              <span className="searchicon" >$</span>
              <Form.Item
                name="retailAmount"
                type="number"
                className="mb-3"
                rules={[
                  {
                    type: 'name',
                    message: 'Please enter retail payment',
                  },
                  {
                    required: true,
                    message: 'Please enter your retail amount ',
                  },
                  {
                    pattern: new RegExp(/^[1-9][0-9.]*$/),
                    message:
                      "It should be number and must not start with zero",
                  }
                ]}
              >
                <Input type="text" min={1} size="large" placeholder="Enter Retail Amount" className="input-field1" style={{ width: "100%" }} onChange={(e) => { setData({ ...data, retailAmount: e.target.value }) }} />
              </Form.Item>
            </div>
            <div className="makepaymentinput">
              <div className="makepaymentheading" >Total BNKD in store payment
                <Tooltip placement="bottomLeft" title="The amount of the customers BNKD wallet is to be used on this transaction." arrow={false} overlayInnerStyle={{ color: "#6B6B6B", backgroundColor: "#fff", width: "15vw", fontSize: "12px" }} overlayStyle={{
                  color: "#fff"
                }}>
                  <span className="infocircle">
                    <img style={{ width: "15px", height: "15px", marginLeft: "5px", cursor: "pointer" }} src={infocircle} />
                  </span>
                </Tooltip>
              </div>
              <span className="searchicon" >$</span>
              <Form.Item
                name="amount"
                type="number"
                className="mb-3"
                rules={[
                  {
                    type: 'name',
                    message: 'Please enter payment',
                  },
                  {
                    required: true,
                    message: 'Please enter your  amount',
                  },
                  {
                    pattern: new RegExp(/^[1-9][0-9.]*$/),
                    message:
                      "It should be number and must not start with zero",
                  },
                  // ({ getFieldValue }) => ({
                  //   validator(_, value) {
                  //     if (Number(getFieldValue('retailAmount')) >= Number(value)) {
                  //       return Promise.resolve();
                  //     }
                  //     return Promise.reject(new Error('Store amount cannot be more than retail amount'));
                  //   },
                  // }),
                ]}
              >

                <Input type="text" min={1} size="large" placeholder="Enter Store Amount" className="input-field1" style={{ width: "100%" }} onChange={(e) => { setData({ ...data, amount: e.target.value }) }} />
              </Form.Item>
              {isValidError && <span style={{ color: "red", position: "relative", top: "-13px" }} >Store amount cannot be more than retail amount</span>}
            </div>
            <div className="makepaymentinput">
              <div className="makepaymentheading" >  Invoice Number
                <Tooltip placement="bottomLeft" title="Optional- In store invoice number that this transaction is linked to." arrow={false} overlayInnerStyle={{ color: "#6B6B6B", backgroundColor: "#fff", width: "15vw", fontSize: "12px" }} overlayStyle={{
                  color: "#fff"
                }}>
                  <span className="infocircle">
                    <img style={{ width: "15px", height: "15px", marginLeft: "5px", cursor: "pointer" }} src={infocircle} />
                  </span>
                </Tooltip>
              </div>
              <Form.Item
                name="invoiceId"
                type="number"
                className="mb-3"
                rules={[
                  {
                    type: 'name',
                    message: 'Please enter payment',
                  },

                ]}
              >
                <Input size="large" placeholder="Enter InvoiceId" className="input-field" onChange={(e) => setData({ ...data, invoiceId: e.target.value })} />
              </Form.Item>
            </div>
            <Button htmlType="submit" className={disabled ? "makepaymentbtndisabled" : "makepaymentbtn"} onClick={() => { setOpenModal(false); setHandleModal(true) }} disabled={disabled}>Make Payment</Button>
          </Form>
        </div>
      </Modal>
      <Modal
        centered
        width={400}
        header={null}
        footer={null}
        open={handleModal}
        className="suremodal"
        closable={false}
      >
        <div className="crossImage" style={{ marginBottom: "10px" }}>
          <img
            src={mediaicon}
            className="text-center"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "20px",
              marginTop: "20px",
              margin: "auto"
            }}
          />
        </div>

        <h2
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "25px",
            marginLeft: "38px",
            fontSize: "20px",
            fontWeight: 600
          }}
        >
          Are you sure you want to deduct money from the BNKD wallet?
        </h2>

        <div
          className="confirmButtons"
          style={{ justifyContent: "center", display: "flex", height: "75px" }}
        >
          <Button
            style={{
              width: "38%",
              height: "73%",
              backgroundColor: "#E7E8EA",
              padding: "10px",
              borderRadius: "15px",
              outline: "none",
              borderColor: " #E7E8EA",
              fontSize: "20px", fontWeight: "400",
            }}
            onClick={() => { setOpenModal(false); setHandleModal(false) }}
          >
            No
          </Button>
          <Button
            style={{
              width: "55%",
              height: "73%",
              marginLeft: "5px",
              backgroundColor: "#003B5B",
              color: "#fff",
              padding: "11px",
              borderRadius: "15px",
              fontSize: "19px", fontWeight: "600"
            }}
            onClick={() => { setOpenModal(false); setHandleModal(false); handlePayment() }}
          >
            Yes
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default PaymentModal;