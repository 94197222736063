import bnkdbluelogo from '../../assets/images/bnkdbluelogo.png'
import Card from "antd/es/card";
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import '../../pages/details/details.less'
import React, { Fragment, useState } from 'react';
import {completeForgotPassword} from '../../api/detailsapi'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { message } from 'antd';

const ResetPassword = () => {
  const [disabled, setDisabled] = useState(true);
  const [completed, setCompleted] = useState(false);
  const [userType, setUserType] = useState(2);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const onFinish = async (values) => {
    setDisabled(true)
    const body = {
      code: searchParams.get('code'),
      password: values.newPassword,
    };
    const response = await completeForgotPassword(body);
    if(response.data?.status===200)
    {
      message.success("Password Reset Sucessfully")
      if (response.data?.output?.userType) {
        setUserType(response.data?.output?.userType)
      }
      setCompleted(true)
      setSearchParams({})
    }
    if(response.data?.status===400)
    {
      setDisabled(false)
      message.alert(response.data.output.error)
    }
  }

  const handleFormChange = () => {
    const hasErrors =
      !form.isFieldsTouched(true) || form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    setDisabled(hasErrors);

  }

  const [form] = Form.useForm();

  return (
    <>
      <div className="profiledetails">
        <div className="cards">
          <Card>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: "5%" }}>
              <div className="bnkdlogo">
                <img src={bnkdbluelogo} alt="bnkd logo"  style={{width:"160px",height:"40px"}}/>
              </div >
              {!completed && (
                <Fragment>
                  <div style={{ color: "#003B5B", fontWeight: "600", marginTop: "30px", marginBottom: '30px' }} className="welcome-header">Set Your Password</div>
                  <div style={{ color: "#BEC4C6", fontWeight: "400", fontSize: "18px", textAlign: "center" }} className="mailcontent"></div>
                  <Form
                    name="name-form"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    className="forgotpassword"
                    autoComplete="off"
                    layout="vertical"
                    onFieldsChange={handleFormChange}
                    form={form}
                  >
                    <div style={{ color: "#6B6B6B" }}>New Password </div>
                    <Form.Item
                      name={"newPassword"}
                      dependencies={['oldPassword']}
                      rules={[{ required: true, message: "Please enter the New Password" },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const isContainsUppercase = /^(?=.*[A-Z]).*$/;
                            const isContainsLowercase = /^(?=.*[a-z]).*$/;
                            const isContainsNumber = /^(?=.*[0-9]).*$/;
                            const isContainsSymbol = /^(?=.*[@$!%*?&]).*$/;
                            const fullTest = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$#!%^*?()&]{8,}$/;
                            if (value?.length < 8) {
                              return Promise.reject(new Error('Must contain minimum of 8 characters.'));
                            }
                            if (!fullTest.test(value)) {
                              return Promise.reject(new Error('Atleast one uppercase, lowercase, number(0-9), special character [@$!%*?&].'));
                            }
                            return Promise.resolve();
                          },
                        })]}
                    >
                      <Input.Password size="large" placeholder="New Password"
                        style={{ width: "100%", height: "50px" }}
                      >
                      </Input.Password>
                    </Form.Item>
                    <div style={{ color: "#6B6B6B" }}>Confirm Password </div>
                    <Form.Item
                      name={"confirmPassword"}
                      dependencies={['newPassword']}
                      rules={[{ required: true, message: "Please confirm the Password" },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('newPassword') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('Password should match with confirm password'));
                          },
                        }),
                      ]}
                    >
                      <Input.Password size="large" placeholder="Confirm Password" style={{ width: "100%", height: "50px" }}></Input.Password>
                    </Form.Item>
                    <Button
                      htmlType="submit"
                      style={{ height: "50px", marginTop: "3%" }}
                      className={disabled ? 'profile_continue_btn_disabled' : 'profile_continue_btn'}
                      type="primary"
                      size="large"
                      disabled={disabled}
                    >
                  Submit
                    </Button>
                  </Form>
                </Fragment>
              )}
              {completed && (
                <Fragment>
                  <div style={{ color: "#BEC4C6", fontWeight: "400", fontSize: "18px", textAlign: "center", paddingTop: '5rem', paddingBottom: '5rem' }} className="mailcontent">Your password has been updated successfully.</div>
                  {userType === 3 && (
                    <div style={{ fontSize: "16px", color: "#6B6B6B", fontWeight: "400" }}>Go back to the BNKD app to login.</div>
                  )}
                </Fragment>
              )}

              {userType !== 3 && (<div style={{ fontSize: "16px", color: "#6B6B6B", fontWeight: "400" }}>Back to <span style={{ color: "#005586", fontWeight: '600', cursor: "pointer" }} onClick={() => { navigate("/") }}>Log in</span></div>)}
            </div>
          </Card>
        </div>
      </div>
    </>
  )
}
export default ResetPassword;
