const ContactUsIcon = ({ val }) => {
	return (
		<>
			{!val ?
				<svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path fill-rule="evenodd" clip-rule="evenodd" d="M10.9741 0.5C11.017 0.5 11.0592 0.503616 11.1001 0.51056L11.237 0.5114C11.441 0.5114 11.636 0.5944 11.778 0.7414L16.843 6.0184C16.977 6.1574 17.0521 6.3444 17.0521 6.5374V15.7034C17.07 18.2124 15.116 20.2624 12.603 20.3644L4.584 20.3654H4.475C2.02506 20.31 0.0601624 18.3286 0.000301831 15.9026L0 4.9904C0.058 2.5094 2.107 0.5114 4.57 0.5114L10.8481 0.51056C10.889 0.503616 10.9312 0.5 10.9741 0.5ZM10.224 2.011L4.572 2.0114C2.915 2.0114 1.539 3.3534 1.5 5.0084V15.7034C1.463 17.4164 2.813 18.8274 4.509 18.8654H12.573C14.242 18.7964 15.564 17.4094 15.5521 15.7094L15.552 7.483L13.5421 7.484C11.7121 7.479 10.2241 5.987 10.2241 4.159L10.224 2.011ZM10.7873 13.1081C11.2013 13.1081 11.5373 13.4441 11.5373 13.8581C11.5373 14.2721 11.2013 14.6081 10.7873 14.6081H5.3873C4.9733 14.6081 4.6373 14.2721 4.6373 13.8581C4.6373 13.4441 4.9733 13.1081 5.3873 13.1081H10.7873ZM8.7424 9.3561C9.1564 9.3561 9.4924 9.6921 9.4924 10.1061C9.4924 10.5201 9.1564 10.8561 8.7424 10.8561H5.3864C4.9724 10.8561 4.6364 10.5201 4.6364 10.1061C4.6364 9.6921 4.9724 9.3561 5.3864 9.3561H8.7424ZM11.724 2.852L11.7241 4.159C11.7241 5.163 12.5411 5.981 13.5441 5.984L14.73 5.983L11.724 2.852Z" fill={val ? "#fff" : "#111111"} />
				</svg> :
				<svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path fill-rule="evenodd" clip-rule="evenodd" d="M10.9741 0.5C11.017 0.5 11.0592 0.503616 11.1001 0.51056L11.237 0.5114C11.441 0.5114 11.636 0.5944 11.778 0.7414L16.843 6.0184C16.977 6.1574 17.0521 6.3444 17.0521 6.5374V15.7034C17.07 18.2124 15.116 20.2624 12.603 20.3644L4.584 20.3654H4.475C2.02506 20.31 0.0601624 18.3286 0.000301831 15.9026L0 4.9904C0.058 2.5094 2.107 0.5114 4.57 0.5114L10.8481 0.51056C10.889 0.503616 10.9312 0.5 10.9741 0.5ZM10.224 2.011L4.572 2.0114C2.915 2.0114 1.539 3.3534 1.5 5.0084V15.7034C1.463 17.4164 2.813 18.8274 4.509 18.8654H12.573C14.242 18.7964 15.564 17.4094 15.5521 15.7094L15.552 7.483L13.5421 7.484C11.7121 7.479 10.2241 5.987 10.2241 4.159L10.224 2.011ZM10.7873 13.1081C11.2013 13.1081 11.5373 13.4441 11.5373 13.8581C11.5373 14.2721 11.2013 14.6081 10.7873 14.6081H5.3873C4.9733 14.6081 4.6373 14.2721 4.6373 13.8581C4.6373 13.4441 4.9733 13.1081 5.3873 13.1081H10.7873ZM8.7424 9.3561C9.1564 9.3561 9.4924 9.6921 9.4924 10.1061C9.4924 10.5201 9.1564 10.8561 8.7424 10.8561H5.3864C4.9724 10.8561 4.6364 10.5201 4.6364 10.1061C4.6364 9.6921 4.9724 9.3561 5.3864 9.3561H8.7424ZM11.724 2.852L11.7241 4.159C11.7241 5.163 12.5411 5.981 13.5441 5.984L14.73 5.983L11.724 2.852Z" fill="white" />
				</svg>
			}
		</>
	)
}
export default ContactUsIcon;