import React, { useEffect, useState } from 'react'
import Chip from '@/assets/settingicons/Chip.svg';
import Mastercard from '@/assets/settingicons/Mastercard.svg';
import Visa from '@/assets/settingicons/Visa.png';
import Discover from '@/assets/settingicons/Discover.png';
import UnionPay from '@/assets/settingicons/UnionPay.png';
import DinersClub from '@/assets/settingicons/DinersClub.png';
import Jcb from '@/assets/settingicons/Jcb.png';
import AmericanExpress from '@/assets/settingicons/AmericanExpress.png';
import { useDispatch ,useSelector} from 'react-redux';
import { setLoader } from '@/store/loginslice/LoaderSlice'

import './style.less';

const cardImages = {
  "discover": Discover,
  "amex": AmericanExpress,
  "mastercard": Mastercard,
  "unionpay": UnionPay,
  "visa": Visa,
  "diners": DinersClub,
  "jcb": Jcb
}

const cardImage=['cardimage1 card-detail ','cardimage2 card-detail','cardimage3 card-detail'];

const CreditCard = ({card}) => {
  const getRestCardNumber = (brand) => {
    if (brand === 'amex') {
      return <><span>XXXX</span> <span>XXXXXX</span></>
    } else {
      return <><span>XXXX</span> <span>XXXX</span> <span>XXXX</span></>
    }
  }
  return (
    <div className="settingcard">
      <div className="render-card">
        <div className='list-wrapper'>
          <div className={cardImage[0]} >
            <div className='chip'>
              <img src={Chip} alt="Chip" />
            </div>
            <div className='card-number'>
              {getRestCardNumber(card.brand)} {(card.brand === 'unionpay' && card.funding !== "debit") && <span>XXX{card.last4?.slice(0, 1)}</span>} <span className='last4'>{card.brand === 'amex' ? `X${card.last4}` : (card.brand === 'unionpay' && card.funding !== "debit") ? card.last4.slice(1, 4) : card.last4}</span>
            </div>
            <div className='card-user'>
              <div className='user-detail'>
                <div>{card.name || 'admin'}</div>
                <div>
                  {Number(card.expMonth) < 10 ? `0${card.expMonth}` : card.expMonth}/{card.expYear?.slice(2, 4)}
                </div>
              </div>
              <div className='card-icon'>
                {card.brand && <img src={cardImages[card.brand]} alt="Mastercard" />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreditCard;
