import { DatePicker, Menu,Space } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
const { RangePicker } = DatePicker;


const currentYear = new Date().getFullYear();
const today = dayjs().format('YYYY-MM-DD');
const week = dayjs().subtract(6, "day").format('YYYY-MM-DD');
const twoWeek = dayjs().subtract(13, "day").format('YYYY-MM-DD');
const thisMonth = dayjs().subtract(new Date().getDate()-1, "day").format('YYYY-MM-DD');
const Data = {
  'Week': [week, today],
  'Two Week': [twoWeek, today],
  'This Month': [thisMonth, today]
}

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const SelectDates = ({ getGraphData }) => {
  const [list, showList] = useState(false);
  const [selected, setSelected] = useState('All Months');
  const [dates, setDates] = useState(null);
  const [value, setValue] = useState(null);
  const [isCustom,setIsCustom]=useState(false)

  const onClick = ({ key, domEvent }) => {
    domEvent.stopPropagation();
    if (key !== 'Custom') {
      showList(false)
      setSelected(key)
      setIsCustom(false)
    }
     else{
      setIsCustom(true)
     }

    if (['Week', 'Two Week', 'This Month'].includes(key)) {
      const payload = {
        type: 1,
        startDate: Data[key][0],
        endDate: Data[key][1],
        year: Data[key][1].split('-')[0]
      }
      getGraphData(payload)
    } else if (key !== 'Custom') {
      const payload = {
        type: 2,
        startDate: `${key}-01-01`,
        endDate: key == new Date().getFullYear() ? today : `${key}-12-31`,
        year: key
      }
      getGraphData(payload)
    }
  };

  const disabledDate = (current) => {
    if (dates && dates[0]) {
      const d = new Date(dates[0].valueOf())
      const day = dayjs(d)
      const lastThirtyDays = day.subtract(30, "day");
      const nextThirtyDays = day.add(30, "day");
      return current >= nextThirtyDays || (current <= lastThirtyDays) || current > dayjs();
    } if (dates && dates[1]) {
      const d = new Date(dates[1].valueOf())
      const day = dayjs(d)
      const lastThirtyDays = day.subtract(30, "day");
      return current <= lastThirtyDays;
    } else {
      return current > dayjs()
    }
  };

  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  const onChange = (date, dateString) => {
    setValue(date)
    showList(false)
    if (date) {
      setSelected(`${dateString[0]} To ${dateString[1]}`)
      const payload = {
        type: 1,
        startDate: dateString[0],
        endDate: dateString[1],
        year: dateString[0].split('-')[0]
      }
      getGraphData(payload)
    } else setSelected('Custom')
  };
  const items = [
    getItem('Week', 'Week'),
    getItem('Two Week', 'Two Week'),
    getItem('This Month', 'This Month'),
    getItem('Select Year', 'Select Year', null, [
      getItem(currentYear, currentYear),
      getItem(currentYear - 1, currentYear - 1),
      getItem(currentYear - 2, currentYear - 2),
      getItem(currentYear - 3, currentYear - 3),
      getItem(currentYear - 4, currentYear - 4),
      getItem(currentYear - 5, currentYear - 5),
      getItem(currentYear - 6, currentYear - 6),
      getItem(currentYear - 7, currentYear - 7),
      getItem(currentYear - 8, currentYear - 8),
      getItem(currentYear - 9, currentYear - 9),
    ]),
    getItem('Custom', 'Custom'),
    // getItem('Custom Dates', 'Custom Dates', null, [getItem('', 'date', <RangePicker onClick={(e) => e.stopPropagation()} />)]),
    getItem(selected, 'Custom',
  isCustom&& <RangePicker
       // className='border-0'
        value={dates || value}
        disabledDate={disabledDate}
        onCalendarChange={(val) => {
          setDates(val);
        }}
        onChange={onChange}
        onOpenChange={onOpenChange}
        changeOnBlur
        onClick={(e) => e.stopPropagation()}
      />
    ),
  ];
  return (
    <div className='d-flex flex-column position-relative'>
      <button onClick={() => showList(!list)}>{selected}</button>
      {list && (
        <Menu
          onClick={onClick}
          style={{
            width: 280,
            height: !isCustom ? 240 : 300,
          }}
          className='overflow-hidden'
          mode="vertical"
          items={items}
        />
      )}
    </div>
  )
};

export default SelectDates;
