import React from "react";
import { CSVLink } from "react-csv";

const headers = [
  { label: "Total Members", key: "totalMembers" },
  { label: "Churn", key: "churn" },
  { label: "Monthly Recurring Revenue", key: "monthlyRecurringRevenue" },
  { label: "Annual Recurring Revenue", key: "annualRecurringRevenue" },
  { label: "Total BNKD Balance", key: "totalBNKDBalance" },
  { label: "Used BNKD Amount", key: "usedBNKDAmount" },
  { label: "Unused BNKD Amount", key: "unusedBNKDAmount" },
  { label: "Total Retail Balance", key: "totalRetailPurchases" },
  { label: "Payments from Other Sources", key: "paymentsFromOtherSources" },
  { label: "BNKD Wallet Spending", key: "BNKDWalletSpending" },
];

function ExportASCSV({ exportData }) {
  return (
    <CSVLink data={[exportData]} headers={headers} filename="ReportData">Export</CSVLink>
  );
}

export default ExportASCSV;
