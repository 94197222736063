import React, { useState } from 'react'
import Modal from 'antd/es/modal/Modal';
import Typography from 'antd/es/typography/Typography';
import defaultCard from "../../../assets/settingicons/defaultCard.svg";
import { makeDefaultCard } from '../../../api/subscribe';
import './DeleteModal.less'
import Spinner from '../../../common/component/Spinner';

const MakeDefaultModal = ({ id, showModal, setShowModal, changeDefaultCard }) => {
  const [loading, setLoading] = useState(false);

  const makeDefaultCreditCard = async () => {
    setLoading(true);
    const res = await makeDefaultCard({ id });
    if (res.status === 200) {
      setShowModal(false);
      setLoading(false);
      changeDefaultCard(id)
    }
  }

  return (
    <div>
      <Spinner loading={loading} />
      <Modal
        open={showModal}
        header={null}
        footer={null}
        onCancel={() => {
          setShowModal(false);
        }}
        className="delete-modal"
      >
        <div className="success-modal">
          <div className="bnkd-logo">
            <img src={defaultCard} alt="DeleteCard" />
          </div>
          <Typography
            className="TextLevelSmallLabel"
          >
            Do you want to make this card as default for all payments?
          </Typography>
          <div className='buttons-wrapper'>
            <div
              className="yes-btn"
              onClick={() => makeDefaultCreditCard()}
            >
              Yes
            </div>
            <div
              className="no-btn"
              onClick={() => setShowModal(false)}
            >
              No
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default MakeDefaultModal