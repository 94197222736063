import React, { useState } from 'react';
import ModalComponent from '../../paymentdetail/Modal';
import PaymentDetail from '../../paymentdetail';
import { useSelector } from 'react-redux';
import './BankDetail.less';

const BankDetails = () => {
  const [showModal, setShowModal] = useState(false);

  const userData = useSelector((state) => state.user);
  const isAdmin = userData?.user.isAdminImpersonation;

  return (
    <div className="settingcard">
      <div className="add-bank">
        <div className={`content ${!isAdmin ? 'disabled' : ''}`}>
          <ModalComponent showModal={showModal} setShowModal={setShowModal} />
          <PaymentDetail heading="Update Your Bank Information" isDisabled={!isAdmin} />
        </div>
        {!isAdmin && <div className="access-message">You lack access to perform this action</div>}
      </div>
    </div>
  );
};

export default BankDetails;
