import Color from 'color'

const colors = {
  primary: Color('#003B5B')
}

const theme = {
  token: {
    colorPrimary: colors.primary.string()
  },
  components: {
    Button: {
      colorPrimary: "#003B5B",
      controlHeightLG: 50
    },
    Form: {
      labelColor: "#6B6B6B",
    },
    Input: {
      colorBorder: colors.primary.alpha(0.5).string(),
      controlHeightLG: 50
    }
  },
  //hashed: false
}

export default theme
