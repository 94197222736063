export function htmlToNodeWithLength(html, length, index) {
  // Only for measurement. Never added to DOM.
  const container = document.createElement("div");
  container.innerHTML = html;

  const fullRange = document.createRange();
  fullRange.setStart(container, 0);
  fullRange.setEnd(container, 1);

  const range = findRangeWithLength(fullRange, length);
  return range.cloneContents();
}

function findRangeWithLength(range, length) {
  if (rangeLength(range) < length) return range;

  // Find the childNode with at least length content.
  for (const childNode of range.endContainer.childNodes) {
    range.setEnd(childNode, lastEndOffset(childNode));
    if (rangeLength(range) >= length) {
      return findRangeWithLength(range, length);
    }
  }

  // There are no child nodes long enough. It's a text node.
  const diff = length - rangeLength(range) + range.endOffset;
  range.setEnd(range.endContainer, diff);
  return range;
}

function lastEndOffset(node) {
  return node.childNodes.length || node.textContent.length;
}

function rangeLength(range) {
  return range.toString().length;
}

export function convertNumber(number) {
  return parseFloat((Math.floor(Number(number) * 100) / 100).toFixed(2)).toFixed(2);
}

// document.querySelectorAll(".detail")[index]?.appendChild(trimmedNode);
