import { combineReducers, configureStore } from '@reduxjs/toolkit'
import LoginReducer from './loginslice/LoginSlice'
import subscribeCustomerReducer from './loginslice/subscribeCusSlice'
import memberShipReducer from './loginslice/mermberShipSlice'
import loaderReducer from './loginslice/LoaderSlice'
import customerReducer from './loginslice/customerSlice'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { sessionService } from 'redux-react-session';

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, LoginReducer)

const reducers = combineReducers({
  user: persistedReducer,
  subscribeCustomer: subscribeCustomerReducer,
  memberShip: memberShipReducer,
  loader:loaderReducer,
  customer:customerReducer
})

const store = configureStore({
  reducer: reducers,
  devTools: true,
});

const persistor = persistStore(store);
sessionService.initSessionService(store)

export { store, persistor };
