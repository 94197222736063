import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SubscriptionForm from './SubscriptionForm';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
// import './styles.scss';

const StripeElementWrapper = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <SubscriptionForm {...props} />
    </Elements>
  );
};

export default StripeElementWrapper;