import React, { useEffect, useState } from "react";
import {Form, Row, Col, Input, Button, message, Tooltip, Modal} from 'antd';
import PropTypes from 'prop-types'
import { useStripe } from "@stripe/react-stripe-js";
import { addbankdetail, getBankDetail } from "@/api/subscribe";
import infocircle from '@/assets/images/Info-Circle.svg'
import Spinner from "@/common/component/Spinner";
import { setScreen } from "@/store/loginslice/LoginSlice";
import { useDispatch } from "react-redux";
import SpinWrapper from '@/components/wrapper/SpinWrapper';
import { setLoader } from '@/store/loginslice/LoaderSlice'
import { generate } from "generate-password-browser";
import { createCustomerProfile, customerSignup, getCustomerProfileByEmail } from "@/api/customerapi";
import { useForm } from "antd/es/form/Form";

const CustomerDetails = ({data, helpers: {setCurrentStep, setUser, setExistingCustomer}}) => {
  const [form] = Form.useForm();

  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showExistingUser, setShowExistingUser] = useState(false)
  const dispatch = useDispatch();

  const onFinish = async (values) => {
    setDisabled(true)
    const signupData = {
      email: values.email,
      password: generate({length: 10, numbers: true, symbols: true})
    }

    try {
      dispatch(setLoader(true))

      const signupRes = await customerSignup(signupData);

      if (signupRes.status === 200) {
        const output = signupRes?.data?.output
        const profileData = {
          firstName: values.firstName,
          lastName: values.lastName,
          phoneNumber: values.phoneNumber,
          isConfirmed: true,
          userId: output?.userId
        }

        const profileRes = await createCustomerProfile(profileData);

        if (profileRes.status === 200) {
          const userProfile = profileRes.data.output.userDetails
          dispatch(setLoader(false))
          setUser(userProfile)
          setCurrentStep(1)
        }
      }
    } catch (error) {
      console.log('signupError', error)
      dispatch(setLoader(false))
      if (error?.response?.data?.error?.errorCode === 1) {
        setShowExistingUser(true)
      } else {
        message.error(error?.response?.data?.error?.message);
      }
    }
  }

  const confirmExistingUser = async () => {
    const email = form.getFieldValue('email')
    setDisabled(true)

    try {
      dispatch(setLoader(true))

      const profileRes = await getCustomerProfileByEmail(email);

      if (profileRes.status === 200) {
        const userProfile = profileRes.data.output.userData
        dispatch(setLoader(false))
        setUser(userProfile)
        setExistingCustomer(true)

        setCurrentStep(1)
      }
    } catch (error) {
      dispatch(setLoader(false))
      message.error(error?.response?.data?.error?.message);
    }
  }

  const handleFormChange = () => {
    const hasErrors =
      !form.isFieldsTouched(true) || form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    setDisabled(hasErrors);
  }

  return (
    <div className={'center-container'}>
      <div className="welcome-container mt-4">
        <Row>
          <Col span={12}>
            <Form
              name="name-form"
              onFinish={onFinish}
              autoComplete="off"
              layout="vertical"
              onFieldsChange={handleFormChange}
              requiredMark={false}
              form={form}
            >
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    name="firstName"
                    label="First Name"
                    className="mb-3"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the customer's first name",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="First Name" className="input-field" type="text" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="lastName"
                    label="Last Name"
                    className="mb-3"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the customer's last name",
                      },
                    ]}
                  >
                    <Input
                      style={{ height: '50px', border: '1px solid rgba(0,85,134,.5)' }}
                      size="large"
                      placeholder="Last Name"
                      className="input-field"
                      type="text"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name="email"
                label="Email"
                className="mb-3"
                rules={[
                  {
                    required: true,
                    message: "Please enter the customer's email address",
                  },
                  {
                    type: 'email',
                    message: 'Please enter a valid email address',
                  },
                ]}
              >
                <Input
                  style={{ height: '50px', border: '1px solid rgba(0,85,134,.5)' }}
                  size="large"
                  placeholder="Email Address"
                  className="input-field"
                  type="text"
                />
              </Form.Item>
              <Form.Item
                name="phoneNumber"
                label="Phone Number"
                className="mb-3"
                rules={[
                  {
                    required: true,
                    message: "Please enter the customer's phone number",
                  }
                ]}
              >
                <Input
                  style={{ height: '50px', border: '1px solid rgba(0,85,134,.5)' }}
                  size="large"
                  placeholder="403-123-4567"
                  className="input-field"
                  type="tel"
                />
              </Form.Item>

              <Button htmlType="submit" className="mt-3" type="primary" size="large" block disabled={disabled}>
                Continue
              </Button>
            </Form>
          </Col>
        </Row>
        <Modal
          title="User Already Exists"
          centered
          open={showExistingUser}
          onOk={() => confirmExistingUser()}
          onCancel={() => setShowExistingUser(false)}
          okText="Proceed"
        >
          <p>A user with that email address already exists in the system.</p>
          <p>Would you like to continue with the existing user?</p>
        </Modal>
      </div>
    </div>
  );
}

export default CustomerDetails
