import DashboardWrapper from "@/components/wrapper/DashboardWrapper"
import SpinWrapper from "@/components/wrapper/SpinWrapper"
import { Steps } from 'antd';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { useState } from "react"
import CustomerDetails from "./steps/CustomerDetails"
import SelectMembership from "./steps/SelectMembership";
import PaymentDetails from "./steps/PaymentDetails";
import Summary from "./steps/Summary";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const AddCustomer = () => {
  const [user, setUser] = useState(null)
  const [membership, setMembership] = useState(null)
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [currentStep, setCurrentStep] = useState(0)
  const [existingCustomer, setExistingCustomer] = useState(false)

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return <CustomerDetails data={{user}} helpers={{setUser, setCurrentStep, setExistingCustomer}} />;
      case 1:
        return (
          <Elements stripe={stripePromise}>
            <PaymentDetails data={{user}} helpers={{setPaymentMethod, setUser, setCurrentStep}} />
          </Elements>
        );
      case 2:
        return <SelectMembership data={{user, paymentMethod}} helpers={{setMembership, setCurrentStep}} />;
      case 3:
        return <Summary data={{user, paymentMethod, membership, existingCustomer}} helpers={{setCurrentStep}} />
    }
  }

  return (
    <DashboardWrapper>
      <SpinWrapper>
        <div className="px-3 mt-3">
          <Steps
            current={currentStep}
            onChange={setCurrentStep}
            items={[
              {
                title: 'Customer Details',
                disabled: true
              },
              {
                title: 'Payment Details',
                disabled: !paymentMethod
              },
              {
                title: 'Select Membership',
                disabled: !user || !paymentMethod
              },
              {
                title: 'Summary',
                disabled: true
              },
            ]}
          />
          {renderStep()}
        </div>
      </SpinWrapper>
    </DashboardWrapper>
  )

}

export default AddCustomer
