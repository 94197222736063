import instance from "../config/axios";

export async function subscribe(data) {
  return instance.post(`${process.env.REACT_APP_BASE_URL}user/subscribe-business-package`, data)
}

export async function addCard(data) {
  return instance.post(`${process.env.REACT_APP_BASE_URL}user/add-card`, data)
}

export async function listCards() {
  return instance.post(`${process.env.REACT_APP_BASE_URL}user/list-card`, null)
}

export async function deleteCard(id) {
  return instance.delete(`${process.env.REACT_APP_BASE_URL}user/delete-card/${id}`)
}

export async function makeDefaultCard(data) {
  return instance.post(`${process.env.REACT_APP_BASE_URL}user/make-default-card-business`, data)
}

export async function addbankdetail(data) {
  return instance.post(`${process.env.REACT_APP_BASE_URL}user/add-bank-details`, data)
}

export async function getBankDetail() {
  return instance.post(`${process.env.REACT_APP_BASE_URL}user/view-bank-details`)
}

export async function listSubscribedCustomer(data) {
  return instance.post(`${process.env.REACT_APP_BASE_URL}user/list-subscribed-customer`, data)
}

export async function viewSubscribedCustomer(data) {
  try{
    const val=await instance.post(`${process.env.REACT_APP_BASE_URL}user/view-subscribed-customer`, data);
    return val;
  }
  catch(err)
  {
    console.log(err)
  }
}
export async function viewCustomerTransactions(data) {
  try{
    const val= await instance.post(`${process.env.REACT_APP_BASE_URL}user/list-transaction`, data);
    return val;
  }
  catch(err)
  {
    console.log(err)
  }
}
