import React from "react";
import { createRoot } from "react-dom/client";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux';
import './index.less';
import { PersistGate } from "redux-persist/es/integration/react";
import { store, persistor } from './store';
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
const domNode = document.getElementById('root');
const root = createRoot(domNode);

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Router>
        <App />
      </Router>
    </PersistGate>
  </Provider >
);

