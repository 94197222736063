import React, { useState } from 'react';
import subscriptionSuccess from "../../assets/images/subscriptionSuccess.svg";
import './Modal.less';
import { Modal, Typography, Input, Button } from 'antd';
import StripeElementWrapper from './StripeElementWrapper';
import { useNavigate } from 'react-router-dom';
import { couponcodeApi } from '../../api/detailsapi';
import { Spin } from 'antd';

const ModalComponent = ({ cardAdding, showModal, setShowModal, getAllCards }) => {
  const subscriptionPage = location.pathname === '/subscription';
  const [success, setSuccess] = useState(false);
  const [stripe, setStripe] = useState(subscriptionPage ? false : true);
  const [couponCode, setCouponCode] = useState('');
  const [percentage, setPercentage] = useState('');
  const [loading,setLoading]=useState(false)
  const navigate = useNavigate();
  const callCouponCodeApi = async () => {
    setLoading(true)
    try{
    const response = await couponcodeApi(couponCode);
    setPercentage(response.data.output.percentage);
    setLoading(false);
    }
    catch(Err)
    {
      setLoading(false)
    }
  }

  return (
    <Modal
      open={showModal}
      header={null}
      footer={null}
      closable={success ? false : true}
      onCancel={() => {
        setShowModal(false);
        location.pathname === '/subscription' && setStripe(false)
      }}
      className="subscription-detail-modal"
      centered
    >
      {success && !cardAdding && (
        <div className="success-modal">
          <div className="bnkd-logo">
            <img src={subscriptionSuccess} alt="subscriptionSuccess" />
          </div>
          <Typography
            className="TextLevelSmallLabel"
          >
            Congratulations! Payment has been Successfully Completed!
          </Typography>
          <div
            className="done-btn"
            onClick={() => {
              setShowModal(false);
              setSuccess(false);
              subscriptionPage && navigate('/membership-packages');
              !subscriptionPage && setStripe(true);
            }}
          >
            Done
          </div>
        </div>
      )}
      {stripe && (
        <div className='add-new-card'>
          <div className="headings">
            Add New Card
          </div>
          <StripeElementWrapper cardAdding={cardAdding} setShowModal={setShowModal} setSuccess={setSuccess} setStripe={setStripe} getAllCards={getAllCards} percentage={percentage} couponCode={couponCode} />
        </div>
      )}
      {!success && !stripe && (
            <Spin spinning={loading}>
        <div className="subscription-modal">
      
          <div className="headings">
            Purchase Subscription
          </div>
          <div className="d-flex w-100" style={{ flexDirection: "column" }}>
            <div style={{ marginTop: "0px",width: "100%", marginBottom: "5px",color:"6B6B6B",fontWeight:"400" }}> Coupon Code</div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Input placeholder='Apply Coupon Code' className="input-field" style={{ height: "50px", marginRight: "10px", width: "70%",color:"#111",fontWeight:"500" }} onChange={(e) => { setCouponCode(e.target.value) }} placeholder="Have A Coupon Code? Enter Here" />
              <Button style={{ backgroundColor: "#003B5B", color: "#fff", padding: "10px", cursor: "pointer", borderRadius: "10px", textAlign: 'center', height: "50px", width: "30%" }} className={couponCode.length > 0 ? "" : "coupondisable"} onClick={() => { callCouponCodeApi() }} disabled={couponCode.length > 0 ? false : true}>Apply</Button>
            </div>  </div>
          <div className="subscription-popup-content">
            <div className="content">
              <div className='total-amount'>
                <div>Total Amount</div>
                <div>$250</div>
              </div>
              <div className='pay-tax'>
                <div>Payable Tax</div>
                <div>$0</div>
              </div>
              {percentage != '' && <div className='pay-tax'>
                <div>Coupon Discount ({percentage}%)</div>
                <div style={{ color: "red", opacity: "0.7" }}>-${(250 * percentage / 100).toFixed(2)}</div>
              </div>}
              <div className='pay-amount'>
                <div>Payable Amount</div>
                <div>{!percentage ? `$250` : `$${(250 - 250 * percentage / 100).toFixed(2)}`}</div>
              </div>
            </div>
          </div>

          <div
            className="pay-now"
            onClick={() => {
              setStripe(true);
            }}
          >
            Pay Now
          </div>
        
        </div>
        </Spin>
      )}
    </Modal>
  )
}

export default ModalComponent