const NewsFeedIcon = ({ val }) => {
    return (
        <>
        {
            !val?
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.77 11.75H15.73C13.72 11.75 12.75 10.77 12.75 8.77V4.73C12.75 2.72 13.73 1.75 15.73 1.75H19.77C21.78 1.75 22.75 2.73 22.75 4.73V8.77C22.75 10.77 21.77 11.75 19.77 11.75ZM15.73 3.25C14.55 3.25 14.25 3.55 14.25 4.73V8.77C14.25 9.95 14.55 10.25 15.73 10.25H19.77C20.95 10.25 21.25 9.95 21.25 8.77V4.73C21.25 3.55 20.95 3.25 19.77 3.25H15.73Z" fill={val ? "#003B5B" : "white"} />
            <path d="M8.27 11.75H4.23C2.22 11.75 1.25 10.86 1.25 9.02V4.48C1.25 2.64 2.23 1.75 4.23 1.75H8.27C10.28 1.75 11.25 2.64 11.25 4.48V9.01C11.25 10.86 10.27 11.75 8.27 11.75ZM4.23 3.25C2.89 3.25 2.75 3.63 2.75 4.48V9.01C2.75 9.87 2.89 10.24 4.23 10.24H8.27C9.61 10.24 9.75 9.86 9.75 9.01V4.48C9.75 3.62 9.61 3.25 8.27 3.25H4.23Z" fill={val ? "#003B5B" : "white"} />
            <path d="M8.27 23.25H4.23C2.22 23.25 1.25 22.27 1.25 20.27V16.23C1.25 14.22 2.23 13.25 4.23 13.25H8.27C10.28 13.25 11.25 14.23 11.25 16.23V20.27C11.25 22.27 10.27 23.25 8.27 23.25ZM4.23 14.75C3.05 14.75 2.75 15.05 2.75 16.23V20.27C2.75 21.45 3.05 21.75 4.23 21.75H8.27C9.45 21.75 9.75 21.45 9.75 20.27V16.23C9.75 15.05 9.45 14.75 8.27 14.75H4.23Z" fill={val ? "#003B5B" : "white"} />
            <path d="M20.5 18.75H14.5C14.09 18.75 13.75 18.41 13.75 18C13.75 17.59 14.09 17.25 14.5 17.25H20.5C20.91 17.25 21.25 17.59 21.25 18C21.25 18.41 20.91 18.75 20.5 18.75Z" fill={val ? "#003B5B" : "white"} />
            <path d="M17.5 21.75C17.09 21.75 16.75 21.41 16.75 21V15C16.75 14.59 17.09 14.25 17.5 14.25C17.91 14.25 18.25 14.59 18.25 15V21C18.25 21.41 17.91 21.75 17.5 21.75Z" fill={val ? "#003B5B" : "white"} />
        </svg>:
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.5 17.25H18.25V15C18.25 14.59 17.91 14.25 17.5 14.25C17.09 14.25 16.75 14.59 16.75 15V17.25H14.5C14.09 17.25 13.75 17.59 13.75 18C13.75 18.41 14.09 18.75 14.5 18.75H16.75V21C16.75 21.41 17.09 21.75 17.5 21.75C17.91 21.75 18.25 21.41 18.25 21V18.75H20.5C20.91 18.75 21.25 18.41 21.25 18C21.25 17.59 20.91 17.25 20.5 17.25Z" fill="#003B5B"/>
        <path d="M22 9.02V4.48C22 3.07 21.36 2.5 19.77 2.5H15.73C14.14 2.5 13.5 3.07 13.5 4.48V9.01C13.5 10.43 14.14 10.99 15.73 10.99H19.77C21.36 11 22 10.43 22 9.02Z" fill="#003B5B"/>
        <path d="M10.5 9.02V4.48C10.5 3.07 9.86 2.5 8.27 2.5H4.23C2.64 2.5 2 3.07 2 4.48V9.01C2 10.43 2.64 10.99 4.23 10.99H8.27C9.86 11 10.5 10.43 10.5 9.02Z" fill="#003B5B"/>
        <path d="M10.5 20.27V16.23C10.5 14.64 9.86 14 8.27 14H4.23C2.64 14 2 14.64 2 16.23V20.27C2 21.86 2.64 22.5 4.23 22.5H8.27C9.86 22.5 10.5 21.86 10.5 20.27Z" fill="#003B5B"/>
        </svg>
        
}
        </>
    )
}

export default NewsFeedIcon;