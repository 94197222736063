import React from 'react'
import './style.less';
import editIcon from '../assets/images/editicon1.png'
import locationimg from '../assets/images/locationimg.png'
import PropTypes from 'prop-types';

const AddressCard = ({ val, handleEdit }) => {
  return (
    <div className='my-2 mt-2 py-1 px-2 d-flex w-100   border align-items-center addresscard' style={{ borderRadius: "10px", margin: "auto", backgroundColor: "#FFFFFF", padding: "0.5%" }}>
      <div className='text-start relative-img locationimg' style={{ width: "15%" }} >
        <img src={locationimg} alt="" />
      </div>
      <div className='text-start' style={{ width: "70%" }} >
        <p className='fw-bolder fs-5 mb-0 addressdetails' style={{ color: "#000000", fontWeight: "500", fontSize: "16px" }}>
          {val.address}{","}{val.city}{","}{val.province}{","}{val.postalCode}
        </p>

      </div>
      <div className='text-end' style={{ width: "15%", textAlign: "end", cursor: "pointer" }}>
        <img onClick={() => handleEdit(val)} src={editIcon} alt="edit icon" />
      </div>
    </div>
  )
}

AddressCard.propTypes = {
  val: PropTypes.any,
  handleEdit: PropTypes.func
}

export default AddressCard;