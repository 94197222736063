import React, { useState } from 'react'
import Modal from 'antd/es/modal/Modal';
import Typography from 'antd/es/typography/Typography';
import DeleteCardLogo from "../../../assets/settingicons/DeleteCard.svg";
import { deleteCard } from '../../../api/subscribe';
import './DeleteModal.less'
import Spinner from '../../../common/component/Spinner';

const DeleteModal = ({ id, showModal, setShowModal, getAllCards }) => {
  const [loading, setLoading] = useState(false);

  const deleteCreditCard = async () => {
    setLoading(true);
    const res = await deleteCard(id);
    if (res.status === 200) {
      getAllCards()
      setShowModal(false);
      setLoading(false);
    }
  }

  return (
    <div>
      <Spinner loading={loading} />
      <Modal
        open={showModal}
        header={null}
        footer={null}
        onCancel={() => {
          setShowModal(false);
        }}
        className="delete-modal"
      >
        <div className="success-modal">
          <div className="bnkd-logo">
            <img src={DeleteCardLogo} alt="DeleteCard" />
          </div>
          <Typography
            className="TextLevelSmallLabel"
          >
            Are you sure you want to
            delete this card?
          </Typography>
          <div className='buttons-wrapper'>
            <div
              className="yes-btn"
              onClick={() => deleteCreditCard()}
            >
              Yes
            </div>
            <div
              className="no-btn"
              onClick={() => setShowModal(false)}
            >
              No
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default DeleteModal