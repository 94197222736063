import { useEffect, useState, useRef } from "react";
import { listCatApi } from "../../api/detailsapi";
import Form from 'antd/es/form';
import './newsfeed.less';
import { uploadImageApi } from "../../api/detailsapi";
import edit from '../../assets/images/edit.svg'
import AddModal from "./AddModal";
import ShowMoreModal from "./ShowMoreModal";
import circleplus from '../../assets/images/circleplus.png'
import { getnewsfeedapi } from "../../api/newfeedapi";
import { Carousel, Spin, message } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { deletenewsfeedapi, createnewsfeedapi, editnewsfeedapi } from '../../api/newfeedapi'
import nonewsfeedimg from '../../assets/images/nonewsfeedimg.svg';
import deletemodalicon from '../../assets/images/deletemodalicon.png'
import Modal from 'antd/es/modal'
import Button from 'antd/es/button'
import SpinWrapper from '../../components/wrapper/SpinWrapper'
import { setLoader } from '../../store/loginslice/LoaderSlice'
import DashboardWrapper from "../../components/wrapper/DashboardWrapper";
import imageCompression from 'browser-image-compression';
import { homeNotificationApi } from "../../api/notificationapi";
import {setIsRead} from '../../store/loginslice/LoginSlice'
const NewsFeedComp = () => {
  const [catData, setCatData] = useState();
  const [addModal, setAddModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [newsFeedList, setNewsFeedList] = useState([])
  const [form] = Form.useForm();
  const [imagevideoArr, setImagevideoArr] = useState([])
  const [checked, setChecked] = useState(false)
  const [values, setValues] = useState({ media: [] })
  const [loading, setLoading] = useState(false)
  const [currentItem, setCurrentItem] = useState({});
  const listInnerRef = useRef();
  const [isEdit, setIsEdit] = useState(false)
  const [resultCount, setResultCount] = useState();
  const [page, setPage] = useState(1);
  const [lspage,setLspage]=useState(1);
  const [lsresultCount,setLsresultCount]=useState(0);
  const [handleDeleteOpen, setHandleDeleteOpen] = useState(false)
  const userData = useSelector(state => state.user?.user);
  const loade = useSelector(state => state.loader.loader);
  const dispatch = useDispatch();
  useEffect(()=>{
    callHomeNotificationApi();
  },[])
  const  callHomeNotificationApi=async()=>{
    const resp = await homeNotificationApi();
    const val=resp.data.output.unReadNotificationCount;
    if(val>0)
    {
    dispatch(setIsRead(true))
    }
  }
  const callApi = async () => {
    const responsecat = await listCatApi({page:lspage,pageLimit:25});
    if (responsecat.data?.status == 200)
    {
      if(lspage===1)
      {
      setCatData(responsecat.data.output.list);
      setLsresultCount(responsecat.data.output.resultCount);
      }
      else
      {
        setCatData([...catData,...responsecat.data.output.list])
      }
    }
  }
  const callgetListApi = async () => {
    dispatch(setLoader(true))
    const newfeedlist = await getnewsfeedapi({
      page: page,
      pageLimit: 6,
      userId: userData.userId
    });
    if (newfeedlist?.data?.status == 200) {
      const newlist = newfeedlist.data.output.list;
      if (page == 1) {
        setNewsFeedList([...newlist])
      }
      else {
        setNewsFeedList([...newsFeedList, ...newlist])
      }
      setResultCount(newfeedlist.data.output.resultCount)
    }
    dispatch(setLoader(false))
  }
  const handleDeleteNewsFeed = async () => {
    const response = deletenewsfeedapi({ id: currentItem?._id })
    const output = newsFeedList?.filter((item) => item._id != currentItem?._id)
    setNewsFeedList(output);
  }
  const handleImageUpload = async (e) => {
    dispatch(setLoader(true))
    try{
    if (e.target.files && e.target.files[0]) {
      const type1 = e.target.files[0].type;
      if (e.target.files[0]) {
        let imgFile = e.target.files[0];
        if (type1.includes('video')) {
          if (parseInt(imgFile.size / 1024 / 1024) > 50) {
            dispatch(setLoader(false))
            message.error("video size must be less than 50mb please compress and add again")
            return;
          }
        }
        if (type1.includes('image')) {
          if (parseInt(imgFile.size / 1024 / 1024) > 5) {
            const options = {
              maxSizeMB: 5,
              maxWidthOrHeight: 1080,
              useWebWorker: true,
            }
            try {
              imgFile = await imageCompression(imgFile, options);
            }
            catch (error) {
              console.log(error)
            }

          }
        }
        let formData = new FormData();
        formData.append("image", imgFile);
        formData.append("path", "logo");
        const response = await uploadImageApi(formData);
        if (values?.media) {
          setValues({ ...values, media: [...values.media, { type: type1, url: response?.data?.output?.imageUrl1?.location }] })
        }

      }
    }
  }
  catch(err)
  {
    dispatch(setLoader(false))
  }
    dispatch(setLoader(false))
  }
  const handleAddClick = async () => {
    dispatch(setLoader(true));
    const newvalues = { ...values, sendNotification: checked }
    setValues({ ...values, sendNotification: checked })
    if (!isEdit) {
      if (newvalues?.id)
        delete newvalues.id;
      if (newvalues?.category)
        delete newvalues.category;
      const response = await createnewsfeedapi(newvalues);
      if (response?.data?.status === 200) {
        setNewsFeedList([...newsFeedList, { ...values, sendNotification: checked, _id: response.data.output._id }])
      }
      dispatch(setLoader(false))
    }
    else {
      if (newvalues?.category)
        delete newvalues.category;
      dispatch(setLoader(true))
      const response = await editnewsfeedapi(newvalues);
      dispatch(setLoader(false))
      if (response?.data?.status === 200) {
        const newArr = newsFeedList.map((item) => {
          if (item._id === values.id) {
            item.categoryId = values.categoryId;
            item.title = values.title;
            item.media = values.media;
            item.sendNotification = checked;
            item.description = values.description;
            item.category = values.category
          }
          return item;
        })
      }
    }
    setAddModal(false);
  }
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      const addedScrollHeight = (scrollTop + clientHeight).toFixed(0);
      if (
        addedScrollHeight == scrollHeight ||
        addedScrollHeight == scrollHeight + 1 ||
        addedScrollHeight == scrollHeight - 1
      ) {
        if (newsFeedList.length <resultCount) {
          setPage((prev) => prev + 1);
        }
      }
    }
  };
  const handlePopScroll=()=>{
    if(lsresultCount>catData.length)
    {
      setLspage(Math.floor(catData.length/10+1))
    }
  }

  const handleFormChange = () => {
    const areTruthy = Object.values(form.getFieldsValue()).every(
      value => value
    );
    const hasErrors = !areTruthy ||
      form.getFieldsError().filter(({ errors }) => errors.length)
        .length > 0;
    setDisabled(hasErrors);
  }
  useEffect(() => {
    callApi();
  }, [lspage])
  useEffect(() => {
    callgetListApi();
  }, [page])

  return (
    <>
      <SpinWrapper>
        {!loade && newsFeedList?.length <= 0 ? <div className="nonewsfeed">
          <img src={nonewsfeedimg} />
        </div> :
          <div className="newsfeed" onScroll={onScroll} ref={listInnerRef} >
            {newsFeedList?.length > 0 && newsFeedList?.map((item) => {
              return (
                <>
                  <div className="newsfeedcol" >
                    <img src={edit} className="newsediticon" onClick={() => {
                      setIsEdit(true); setAddModal(true);
                      setCurrentItem(item);
                    }} />
                    <div className="newsimg">
                      <Carousel autoplay={true} effect={'fade'} autoplaySpeed={3000}>
                        {Array.isArray(item?.media) && item?.media?.map((val, index) => {
                          return (
                            <>
                              {val.type.slice(-3) != "mp4"
                                ? <img src={val.url} className="carouselimage" />
                                : <video className="carouselimage" controls>
                                  <source src={val.url} type="video/mp4"></source>
                                </video>
                              }
                            </>
                          )
                        })}
                      </Carousel>
                      <div className="newsfeedtitle">{item?.category?.name}</div>
                    </div>
                    <div className="newfeedcontent" >
                      <div clasName="newsfeedtitlehead" style={{ color: "#003B5B", fontSize: "20px", fontWeight: "600", marginBottom: "5px" }}>{item?.title}</div>
                      <div style={{ color: "#6B6B6B" }}>
                        {item?.description?.substring(0, 190)}
                        <span style={{ color: "#005586", fontWeight: "600", marginLeft: '6px', cursor: 'pointer' }} onClick={() => { setShowModal(true); setCurrentItem(item) }}>
                          {item?.description?.length > 190 ? "Read More" : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              )
            })}
          </div>
        }
        <div className="newsplusicon" onClick={() => { setIsEdit(false); setAddModal(true); setCurrentItem({}); setImagevideoArr([]) }}>
          <img src={circleplus} style={{ width: "100%", height: "100%", cursor: "pointer" }} />
        </div>
        {addModal && <AddModal addModal={addModal} isEdit={isEdit} catData={catData} setAddModal={setAddModal} imagevideoArr={imagevideoArr} setImagevideoArr={setImagevideoArr} handleImageUpload={handleImageUpload} setValues={setValues} values={values} loading={loading} checked={checked} setChecked={setChecked} currentItem={currentItem} handleAddClick={handleAddClick} handleDeleteNewsFeed={handleDeleteNewsFeed} callgetListApi={callgetListApi} setHandleDeleteOpen={setHandleDeleteOpen} handlePopScroll={handlePopScroll}/>}
        <ShowMoreModal showModal={showModal} setShowModal={setShowModal} currentItem={currentItem} />
        {handleDeleteOpen && <Modal
          centered
          width={390}
          header={null}
          footer={null}
          open={handleDeleteOpen}
          className="deletemodal"
          closable={false}
        >
          <div className="crossImage">
            <img
              src={deletemodalicon}
              className="text-center"
            />
          </div>
          <div
            className="heading"
          >
            Are you sure you want to delete this News Feed?
          </div>
          <div
            className="confirmButtons"
          >
            <Button
              className="yesbtn"
              onClick={() => { handleDeleteNewsFeed(); setAddModal(false); setHandleDeleteOpen(false) }}
            >
              Yes
            </Button>
            <Button
              className="nobtn"
              onClick={() => { setHandleDeleteOpen(false); setAddModal(false) }}
            >
              No
            </Button>
          </div>
        </Modal>}
      </SpinWrapper>
    </>
  )
}

const NewsFeed = () => {
  return (
    <DashboardWrapper>
      <NewsFeedComp />
    </DashboardWrapper>
  )
}
export default NewsFeed;