import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import { columns } from './TableData';
import profileLogo from '../../assets/settingicons/profileLogo.svg';
import Filters from '../membershippackage/Filters';
import { useOutsideAlerter } from '../../utils/outsideClick';
import dummyprofile from '../../assets/images/dummyprofile.png'
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import "./style.less";

const Table = ({ resultCount, getList, filterModal, status, filterData, filterStatus, setStatus, showFilterModal, setSelectedId }) => {
  const wrapperRef = useRef(null);
  const loader = useSelector(state => state.loader.loader);
  const listInnerRef = useRef();
  const [isLoading, setIsLoading] = useState(false)
  const closeModal = () => {
    showFilterModal(false);
  }
  useOutsideAlerter(wrapperRef, filterModal, closeModal);
  const navigate = useNavigate();

  const showDetail = (id) => {
    setSelectedId(id);
    navigate(`?id=${id}`)
  }

  const onScroll = (e) => {
    if (isLoading) {
      e.preventDefault()
    }
    if (listInnerRef.current && filterData?.length < resultCount) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (
        Math.round(scrollTop + clientHeight) === scrollHeight
      ) {
        if (!isLoading) {
          setIsLoading(true)
          getList(filterData?.length / 20 + 1).then(() => {
            listInnerRef.current.scrollTop = scrollTop
            setIsLoading(false)
          });
        }
      }
    }
  };

  return (
    <div className='table-wrapper mt-3'>
      <div className='filter-wrapper'>
        {filterModal && (
          <Filters
            subscribedCustomers
            input1="Active"
            input2="Inactive"
            status={status}
            setStatus={setStatus}
            filterList={filterStatus}
            ref={wrapperRef}
          />
        )}
      </div>
      <table>
        <thead>
          <tr className='head-row'>
            {columns?.map((item, ind) => (
              <th className={`table-head table-head${ind}`} width={item.width} onClick={() => { item.title == 'Status' && showFilterModal(!filterModal) }}>
                {item.title} {item.title == 'Status' && <DownOutlined />}
                { filterModal&&<div className="triangle"></div>}
              </th>

            ))}
          </tr>
        </thead>
        <tbody ref={listInnerRef} onScroll={onScroll}>
          {filterData.length === 0 && !loader && (
            <div className="no-subscription">No Subscriptions!</div>
          )}
          {!loader && filterData?.map((item) => (
            <tr className='body-row' >
              <td className='name' onClick={() => showDetail(item.userId)}>
                <img src={item?.profilePicture ? item?.profilePicture : dummyprofile} alt="profile" />
                {item.name}
              </td>
              <td className="email">{item.email}</td>
              <td className='package'>{item.package}</td>
              <td className='since'>{dayjs(item.since).format('MM/DD/YYYY')}</td>
              <td>
                <div className={`${item.status === "active" ? 'status active' : 'status inactive'}`}>
                  {item.status}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Table
