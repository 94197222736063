import React, { useEffect, useState } from 'react';
import { XAxis, YAxis, CartesianGrid, Tooltip, AreaChart, Area, ResponsiveContainer } from 'recharts';
import DashboardWrapper from '../../components/wrapper/DashboardWrapper';
import { getBusinessGraphData, getBusinessReport } from '../../api/reportapi';
import { ArrowUpOutlined, ArrowDownOutlined, DownOutlined } from '@ant-design/icons';
import { homeNotificationApi } from '../../api/notificationapi';
import { setIsRead } from '../../store/loginslice/LoginSlice';
import { setLoader } from '../../store/loginslice/LoaderSlice';
import SpinWrapper from '../../components/wrapper/SpinWrapper';
import { useDispatch } from 'react-redux';
import SelectDates from './SelectDates';
import ExportASCSV from './export';
import './style.less';
import { convertNumber } from '../membershippackage/helper';

function ReportComponent() {
  let [barGraphData, setBarGraphData] = useState({});
  let [exportData, setExportData] = useState({});
  let [businessReport, setBusinessReport] = useState({});
  let [position, setPosition] = useState({});
  let [max, setMax] = useState();
  const [chartHeight, setChartHeight] = useState(480);
  const dispatch = useDispatch();

  useEffect(() => {
    getReportData();
    getGraphData();
  }, []);

  useEffect(() => {
    callHomeNotificationApi();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setChartHeight(300);
      } else {
        setChartHeight(480);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const callHomeNotificationApi = async () => {
    const resp = await homeNotificationApi();
    const val = resp.data.output.unReadNotificationCount;
    if (val > 0) {
      dispatch(setIsRead(true));
    }
  };

  const getReportData = async () => {
    dispatch(setLoader(true));
    const res = await getBusinessReport();
    if (res.data?.status == 200) {
      setBusinessReport(res.data.output);
      const { totalMember, annuallyData, bnkdData, bnkdRetailData, churnData, monthlyData } = res.data.output;

      setExportData({
        totalMembers: totalMember,
        churn: churnData.total,
        monthlyRecurringRevenue: monthlyData.total,
        annualRecurringRevenue: annuallyData.total,
        totalBNKDBalance: bnkdData.usedAmount + bnkdData.unUsedAmount,
        usedBNKDAmount: bnkdData.usedAmount,
        unusedBNKDAmount: bnkdData.unUsedAmount,
        totalRetailPurchases: bnkdRetailData.otherAmount + bnkdRetailData.retailAmount,
        paymentsFromOtherSources: bnkdRetailData.otherAmount,
        BNKDWalletSpending: bnkdRetailData.retailAmount,
      });
    }
    dispatch(setLoader(false));
  };

  const getGraphData = async (data) => {
    const payload = {
      type: 2,
      year: 2023,
    };

    const res = await getBusinessGraphData(data || payload);

    if (res.data?.status == 200) {
      let result = res.data.output?.data?.reduce((a, b) => Math.max(a, b.total), -Infinity);
      setMax(result);
      setBarGraphData(res.data.output?.data);
    }
  };

  const getStrokeDasharray = (usedAmount, unUsedAmount) => {
    const strokeDasharray = Math.round((usedAmount / unUsedAmount) * 360);
    return strokeDasharray;
  };

  const getTotalAmount = (amount1, amount2) => {
    let total = Number(convertNumber(amount1)) + Number(convertNumber(amount2));
    total = convertNumber(total);
    const totalStr = parseInt(total).toString();

    if (totalStr.length > 5 && totalStr.length <= 8) {
      return `${total / 1000}K`;
    } else if (totalStr.length > 8 && totalStr.length <= 10) {
      return `${total / 100000}L`;
    } else if (totalStr.length > 10 && totalStr.length <= 12) {
      return `${total / 1000000}M`;
    } else if (totalStr.length > 12) {
      return `${total / 1000000000}B`;
    } else return total;
  };

  const CustomTooltip = ({ active, payload, label, ...rest }) => {
    if (active && payload?.length) {
      const yPosition = 430 - (payload[0]?.value / max) * 550;
      setPosition({
        y: payload[0]?.value ? (yPosition < 0 ? -20 : yPosition) : 400,
        x: payload[0]?.value ? rest.coordinate?.x - 40 : rest.coordinate?.x - 30,
      });

      return (
        <div className="custom-tooltip">
          <p className="label">${payload[0].value}</p>
        </div>
      );
    }

    return null;
  };

  // const geTickCount = (value) => {
  //   console.log(value,"value...")
  // //   if (value % 1000 === 0) return 6;
  // //   else if (value % 9 === 0) return 10;
  // //   else if (value % 8 === 0 && value % 100 !== 0) return 9;
  // //   else if (value % 7 === 0) return 8;
  // //   else if (value % 6 === 0) return 7;
  // //   else if (value % 5 === 0 && value % 100 !== 0) return 6;
  // //   else if (value % 4 === 0 && value % 100 !== 0) return 5;
  // //   else if (value % 13 === 0) return 7;
  // //   else if (value % 17 === 0) return 8;
  // //   else if (value % 19 === 0) return 8;
  // //   else if (value % 11 === 0) return 12;
  // //  else if (value % 23 === 0) return 12;
  // //  else if (value % 29 === 0) return 12;
  // // }
  // }

  return (
    <SpinWrapper>
      <div className="report-wrapper">
        <div className="header-report" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ padding: '10px', fontSize: '20px', fontWeight: '600', color: '#003B5B' }}>Transactions</div>
          {exportData && <ExportASCSV exportData={exportData} />}
        </div>
        {Object.keys(businessReport).length > 0 ? (
          <div className="report-page">
            <div className="header d-flex flex-wrap w-100 justify-content-between mb-2">
              <div className="item">
                <div className="mb-2 item-name">Total Members</div>
                <div className="totalMember">{businessReport?.totalMember}</div>
              </div>
              <div className="item">
                <div className="mb-2 item-name">Churn</div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="churnData">{businessReport?.churnData?.total}</div>
                  <div
                    className={
                      businessReport?.churnData?.percentage != 0
                        ? businessReport?.churnData?.percentage > 0
                          ? 'textsuccess'
                          : 'textdanger'
                        : 'textZero'
                    }
                  >
                    {businessReport?.churnData?.percentage != 0
                      ? businessReport?.churnData?.percentage > 0
                        ? `+ ${convertNumber(businessReport?.churnData?.percentage)}%`
                        : `- ${convertNumber(businessReport?.churnData?.percentage)}%`
                      : '0'}
                    <span className="ml-1 mb-2">
                      {businessReport?.churnData?.percentage > 0 && <ArrowUpOutlined size={16} />}
                      {businessReport?.churnData?.percentage < 0 && <ArrowDownOutlined size={16} />}
                    </span>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="mb-2 item-name">Monthly recurring revenue</div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="monthlyData">
                    ${convertNumber(businessReport?.monthlyData?.total).toLocaleString()}
                  </div>
                  <div
                    className={
                      businessReport?.monthlyData?.percentage != 0
                        ? businessReport?.monthlyData?.percentage > 0
                          ? 'textsuccess'
                          : 'textdanger'
                        : 'textZero'
                    }
                  >
                    {businessReport?.monthlyData?.percentage != 0
                      ? businessReport?.monthlyData?.percentage > 0
                        ? `+ ${convertNumber(businessReport?.monthlyData?.percentage)}%`
                        : `- ${convertNumber(businessReport?.monthlyData?.percentage)}%`
                      : '0'}
                    <span className="ml-1 mb-2">
                      {businessReport?.monthlyData?.percentage > 0 && <ArrowUpOutlined size={16} />}
                      {businessReport?.monthlyData?.percentage < 0 && <ArrowDownOutlined size={16} />}
                    </span>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="mb-2 item-name">Annual Recurring Revenue</div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="annuallyData">
                    ${convertNumber(businessReport?.annuallyData?.total).toLocaleString()}
                  </div>
                  <div
                    className={
                      businessReport?.annuallyData?.percentage != 0
                        ? businessReport?.annuallyData?.percentage > 0
                          ? 'textsuccess'
                          : 'textdanger'
                        : 'textZero'
                    }
                  >
                    {businessReport?.annuallyData?.percentage != 0
                      ? businessReport?.annuallyData?.percentage > 0
                        ? `+ ${convertNumber(businessReport?.annuallyData?.percentage)}%`
                        : `- ${convertNumber(businessReport?.annuallyData?.percentage)}%`
                      : '0'}
                    <span className="ml-1 mb-2">
                      {businessReport?.annuallyData?.percentage > 0 && <ArrowUpOutlined size={16} />}
                      {businessReport?.annuallyData?.percentage < 0 && <ArrowDownOutlined size={16} />}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-wrap justify-content-between">
              <div className="content-left">
                <div className="left-header w-100 d-flex justify-content-between align-items-start">
                  <div className="earning">Earning Overview</div>
                  <div className="button-wrapper d-flex justify-content-end align-items-center">
                    <SelectDates getGraphData={getGraphData} />
                  </div>
                </div>
                {barGraphData && (
                  <ResponsiveContainer minWidth={340} width="100%" height={chartHeight} className="responsiveContainer">
                    <AreaChart data={barGraphData} margin={{ top: 10, right: 0, left: 0, bottom: 0 }}>
                      <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="0%" stopColor="rgba(0, 85, 134, 0.3)" />
                          <stop offset="100%" stopColor="rgba(0, 85, 134, 0)" />
                        </linearGradient>
                        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="0%" stopColor="rgba(0, 85, 134, 0.3)" />
                          <stop offset="100%" stopColor="rgba(0, 85, 134, 0)" />
                        </linearGradient>
                      </defs>
                      <XAxis
                        padding={{ left: 2, right: 15 }}
                        dataKey="label"
                        axisLine={false}
                        tickLine={false}
                        className="mb-3"
                      />
                      <YAxis
                        tickLine={false}
                        dx={-12}
                        //geTickCount(Math.ceil(max / 50) * 50 || 100)
                        tickCount={10}
                        axisLine={false}
                        tickFormatter={(item) => (item == 0 ? 0 : item > 999 ? `${(item / 1000).toFixed(3)}k` : item)}
                        domain={[0, Math.ceil(max / 50) * 50 || 100]}
                      />
                      <CartesianGrid strokeDasharray="0" />
                      <Tooltip
                        content={<CustomTooltip />}
                        cursor={{ fill: 'transparent' }}
                        position={position}
                        separator="false"
                      />
                      <Area
                        type="monotone"
                        dataKey="total"
                        stroke="#005586"
                        fillOpacity={1}
                        fill="url(#colorUv)"
                        strokeWidth={2}
                        padding={{ bottom: 10 }}
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                )}
              </div>

              <div className="content-right">
                <div className="bnkd-amount p-3">
                  <div className="heading mb-2">BNKD Amount</div>
                  <div className="circle d-flex justify-content-center position-relative">
                    <svg viewBox="0 0 120 120">
                      <circle cx="60" cy="60" r="45" id="used" />
                      <circle
                        cx="60"
                        cy="60"
                        r="47"
                        id="unused"
                        strokeDasharray={`${getStrokeDasharray(
                          businessReport?.bnkdData?.usedAmount,
                          businessReport?.bnkdData?.unUsedAmount
                        )}, 360`}
                      />
                    </svg>
                    <div className="center-amount">
                      <div className="total-amount">
                        ${getTotalAmount(businessReport?.bnkdData?.usedAmount, businessReport?.bnkdData?.unUsedAmount)}
                      </div>
                      <div className="total-heading">Total BNKD Balance</div>
                    </div>
                  </div>
                  <div className="item used">
                    <div>
                      <span /> Used BNKD Amount:{' '}
                      <b className="text-dark">${getTotalAmount(businessReport?.bnkdData?.usedAmount, 0)}</b>
                    </div>
                  </div>
                  <div className="item unused">
                    <div>
                      <span /> Unused BNKD Amount:{' '}
                      <b className="text-dark">${getTotalAmount(businessReport?.bnkdData?.unUsedAmount, 0)}</b>
                    </div>
                  </div>
                </div>

                <div className="retail-purchases p-3">
                  <div className="heading mb-2">Retail Purchases</div>
                  <div className="circle d-flex justify-content-center position-relative">
                    <svg viewBox="0 0 120 120">
                      <circle cx="60" cy="60" r="45" id="used" />
                      <circle
                        cx="60"
                        cy="60"
                        r="45"
                        id="unused"
                        strokeDasharray={`${getStrokeDasharray(
                          businessReport?.bnkdRetailData?.otherAmount,
                          businessReport?.bnkdRetailData?.retailAmount
                        )} 360`}
                      />
                    </svg>
                    <div className="center-amount">
                      <div className="total-amount">
                        $
                        {getTotalAmount(
                          businessReport?.bnkdRetailData?.otherAmount,
                          businessReport?.bnkdRetailData?.retailAmount
                        )}
                      </div>
                      <div className="total-heading">Total Retail Purchases</div>
                    </div>
                  </div>
                  <div className="item used">
                    <div>
                      <span /> Payments from Other Sources:{' '}
                      <b className="text-dark">${getTotalAmount(businessReport?.bnkdRetailData?.otherAmount, 0)}</b>
                    </div>
                  </div>
                  <div className="item unused">
                    <div>
                      <span /> BNKD Wallet Spending:{' '}
                      <b className="text-dark">${getTotalAmount(businessReport?.bnkdRetailData?.retailAmount, 0)}</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </SpinWrapper>
  );
}

const Reports = () => {
  return (
    <DashboardWrapper>
      <ReportComponent />
    </DashboardWrapper>
  );
};

export default Reports;
